<template>
    <div class="modal fade" id="modal_faturamento_last6month" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" >
            <div class="modal-content">      
                <div class="modal-header" style="padding-bottom: 0px;padding-top: 10px;border-bottom-width: 0px;">
                  <!--   <h5 class="modal-title justify-content-between flex-wrap flex-grow-1 mt-n2 mt-lg-n1 cor_texto_1" id="modal_faturamento_last6month">Informações por produto/servicos </h5> -->
                        <div class="card-header border-0 py-5 justify-content-between flex-wrap flex-grow-1 mt-n2 mt-lg-n1" style="padding-left: 0px;">
                            <div>
                                <span class="card-label fw-bolder fs-6 mb-1 cor_texto_1">Informações por produto/servicos </span><br>
                                <span class="text-muted fw-bold fs-8 cor_texto_2">{{ title }}</span>
                            </div>
                        </div>
                        <a class="btn btn-bg-white btn-flex btn-icon-tertiary btn-text-tertiary dropdown" :class="{disabled: loadingExport}" :data-kt-indicator="loadingExport ? 'on' : 'off'" id="kt_button_2" style="padding-right: 0px;">
                          <span class="text-white indicator-label btn-text-tertiary ">
                            <button class="btn background_btn_1_opct dropdown-toggle " type="button" id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: #f96868">
                              Exportar
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                              <li><button class="dropdown-item" type="button" @click="exportarXLSX()">Excel</button></li>
                            <li><button class="dropdown-item" type="button" @click="exportPDF2(datePicker)">PDF</button></li>
                            </ul>
                          </span>
                          <span class="indicator-progress btn-text-tertiary">
                            Exportando PDF...
                            <span class="spinner-border spinner-border-sm align-middle ms-2 btn-text-tertiary"></span>
                          </span>
                        </a>

                         <FilterModal :filtersInicial="filtersInicial.data" id="filter-modal-faturamento" @click="bloqueiaGrafico = true " @filtrarModal="filtradados($event.data)"></FilterModal> 
                     <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     
                </div>

                <ContentLoader :animate="true"  viewBox="0 0 1000 550" v-if="animate || loading">

				        <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
                        <circle cx="879" cy="123" r="11" />
                        <circle cx="914" cy="123" r="11" />
                        <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
                        <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
                        <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
                        <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
                        <circle cx="880" cy="184" r="11" />
                        <circle cx="915" cy="184" r="11" />
                        <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
                        <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
                        <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
                        <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
                        <circle cx="881" cy="242" r="11" />
                        <circle cx="916" cy="242" r="11" />
                        <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
                        <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
                        <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
                        <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
                        <circle cx="882" cy="303" r="11" />
                        <circle cx="917" cy="303" r="11" />
                        <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
                        <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
                        <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
                        <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
                        <circle cx="881" cy="363" r="11" />
                        <circle cx="916" cy="363" r="11" />
                        <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
                        <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
                        <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
                        <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
                        <circle cx="882" cy="424" r="11" />
                        <circle cx="917" cy="424" r="11" />
                        <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
                        <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
                        <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
                        <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
                        <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
                        <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
                        <circle cx="882" cy="484" r="11" />
                        <circle cx="917" cy="484" r="11" />
                        <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
                        <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
                        <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
                        <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
                        <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
                        <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
                        <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
                        <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
                        <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
                        <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />

   				</ContentLoader>    


                <div class="modal-body" v-if="!animate && !loading" style="padding-top: 0px;">      

                      <div class="row g-5 g-xl-12" :class="animate ? 'hideApex' : ''">
                        <div class="col-xl-6 col-12 mb-4" id="graph">
                            <!--begin::Stats Widget 1-->

                            <div class="card card-custom bgi-no-repeat gutter-b card-stretch border border-gray rounded" >

                                        <div v-if="isCrescimentoFaturamento" class="d-flex justify-content-end">
                                            <el-tooltip class="item" effect="dark" content="Crescimento no periodo selecionado." placement="top" cursor="pointer">
                                                <div class="bola">
                                                        <p class="text-bolder text-white text-center">
                                                        <i class="fas fa-arrow-up fa-sm text-white"></i> {{valueCrescimentoFaturamento}}%</p>
                                                </div>
                                            </el-tooltip>
                                        </div>

                                    <p class=" cor_texto_1 fs-3  m-3">Faturamento:</p>

                                <div class="mb-4 "  v-if="aparecerGrafico">
                                    <apexchart
                                        class="mixed-widget-2-chart card-rounded-bottom"
                                        :options="chartFaturamento"
                                        :series="seriesFaturamento"
                                        :categories="categoriesFaturamento"
                                        :height="200"
                                         width="100%"
                                        >
                                    </apexchart>
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::Stats Widget 1-->
                        </div>
                        <div class="col-xl-6 col-12 mb-4"  id="graph"  >
                            <!--begin::Stats Widget 3-->
                            <div class="card card-custom bgi-no-repeat gutter-b card-stretch border border-gray rounded " >
                                <!--begin::body-->
                                        <div v-if="isCrescimentoRevisoes" class="d-flex justify-content-end">                                        
                                            <el-tooltip class="item" effect="dark" content="Crescimento no periodo selecionado." placement="top">
                                                <div class="bola">
                                                        <p class="text-bolder text-white text-center">
                                                        <i class="fas fa-arrow-up fa-sm text-white"></i> {{valueCrescimentoRevisoes}}%</p>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                  <span class="cor_texto_1  fs-3 m-3">Revisões:</span>
                                <div class="mb-4" v-if="aparecerGrafico">
                                    <apexchart 
                                        class="mixed-widget-2-chart card-rounded-bottom"
                                        :options="chartRevisoes"
                                        :series="seriesRevisoes"
                                        :categories="categoriesFaturamento"
                                        :height="200"
                                        width="100%"
                                        >
                                    </apexchart>
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::Stats Widget 3-->
                        </div>
                    </div>          
                    <div class="col-xl-12" >

                        <!--end:Grafico-->
                        <div class="table-responsive" >
                            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" id="constructorpdf"> 


                                <thead class="thead-light cor_texto_1">
                                    <tr>
                                        <th style="text-align: center;  background: #ecedf2;  border-bottom-width: 1px; solid"  class="fw-bolder background: #ebedf2" colspan="6">Concessionária Total</th>
                                        <th style="text-align: center;  border-left: 1px solid #F4DA71;  background: #F4DA71; border-bottom-width: 1px ;"  class="fw-bolder" :colspan="temPacote ? '7' : '4'">Produtos e Serviços {{distribuidor.distribuidor.charAt(0).toUpperCase() + distribuidor.distribuidor.slice(1)}}</th>
                                    </tr>
                                    <tr class="fw-bolder"  style="border-bottom:  1px solid #ecedf2;"  >
                                        <th style="text-align: center; background: #ecedf2;" >Período</th>

                                        <th style="text-align: center; background: #ecedf2;" class="px-4">Revisões</th>

                                        <th style="text-align: center; background: #ecedf2;" class="px-4">Produtos <br> Geral <br/></th>

                                        <th style="text-align: center; white-space: nowrap; background: #ecedf2;" class="px-4">TM <br> Geral <br/></th>

                                        <th style="text-align: center; background: #ecedf2;" class="px-4">Total</th>

                                        <th style="text-align: center; white-space: nowrap;background: #ecedf2;" class="px-4">Total <br>Não realizado <br/></th>

                                        <th style="text-align: center; background: #F4DA71;" class="px-4" v-if="temPacote">Pacotes</th>

                                        <th style="text-align: center; background: #F4DA71; white-space: nowrap;" class="px-4">Produtos <br>{{distribuidor.distribuidor.charAt(0).toUpperCase() + distribuidor.distribuidor.slice(1)}} <br/></th>

                                        <th style="text-align: center; background: #F4DA71; white-space: nowrap;" class="px-4" v-if="temPacote">Pacotes <br>(%)<br/></th>

                                        <th style="text-align: center; background: #F4DA71; white-space: nowrap;" class="px-4" v-if="temPacote">Pacotes <br>Perdidos (%)<br/></th>

                                        <th style="text-align: center; background: #F4DA71; white-space: nowrap;" class="px-4">TM <br>{{distribuidor.distribuidor.charAt(0).toUpperCase() + distribuidor.distribuidor.slice(1)}} <br/> </th>

                                        <th style="text-align: center; white-space: nowrap; background: #F4DA71;" class="px-4">Valor <br> {{distribuidor.distribuidor.charAt(0).toUpperCase() + distribuidor.distribuidor.slice(1)}} <br/></th>

                                        <th style="text-align: center; white-space: nowrap; background: #F4DA71;" class="px-4">Faturamento <br>Anual <br/> </th>
                                    </tr>
                                </thead>
                                <tbody class="cor_texto_2">
                                    <tr  style="border-bottom:  1px solid #ecedf2;"  v-for="(item, index) in dados" :key="index">
                                        <td>
                                            <div class="d-flex justify-content-center flex-column">
                                                <span class=" fw-bold  d-block fs-7 text-center">{{ item.dia +"/"+item.mes +"/"+item.ano }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center">{{ item.revisoes }}</span>
                                            </div>
                                        </td>
                                         <td>
                                            <div class="d-flex justify-content-center flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center">{{ item.totalProdutosGeral }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center flex-column">	
                                                 <span class=" fw-bold  d-block fs-7 text-center"  :class="item.ticketMedioGeral >=  metas.metaticketmedio  ? 'cor_indicadores_1' :  item.ticketMedioGeral >=  metaTMWarning ? 'cor_indicadores_3' : 'cor_indicadores_4'">{{ item.ticketMedioGeral.toFixed(2) }}</span>
                                            </div>
                                        </td>    
                                         <td>
                                            <div class="d-flex justify-content-center flex-column">
                                                <span class=" fw-bold  d-block fs-7 text-center" style="white-space: nowrap;">{{ "R$ "+item.valorTotal.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                            </div>
                                        </td>
                                         <td style="border-right: 2px solid #F4DA71;">
                                            <div class="d-flex justify-content-center flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center" style="white-space: nowrap;">{{ "R$ "+item.valorTotalNaoRealizado.toLocaleString('pt-Br') }}</span>
                                            </div>
                                        </td>
                                         <td v-if="temPacote">
                                            <div class="d-flex justify-content-center flex-column">
                                                <span class=" fw-bold  d-block fs-7 text-center" >{{ item.pacotes }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center" >{{ item.totalProdutosDistribuidor }}</span>
                                            </div>
                                        </td>
                                        <td v-if="temPacote">
                                            <div class="d-flex justify-content-center flex-column">
                                                <span class=" fw-bold  d-block fs-7 text-center" :class="item.percentualConversaoPacotes >= metas.metaconversao ? 'cor_indicadores_1' : item.percentualConversaoPacotes >= metaConversaoWarning ? 'cor_indicadores_3':'cor_indicadores_4'">   {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(item.percentualConversaoPacotes)}}% </span>
                                            </div>
                                        </td>
                                         <td v-if="temPacote">
                                            <div class="d-flex justify-content-center flex-column">
                                                <span class=" fw-bold  d-block fs-7 text-center" >{{ new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format( item.percentualConversaoPacotesNaoRealizado)}}%</span>
                                            </div>
                                        </td>
                                        <td>
                                             <div class="d-flex justify-content-center flex-column">	
                                                 <span class=" fw-bold  d-block fs-7  text-center"   :class="item.ticketMedioDistribuidor >=  metas.metaticketmedio  ? 'cor_indicadores_1' : item.ticketMedioDistribuidor >= metaTMWarning ? 'cor_indicadores_3':'cor_indicadores_4'" > {{ item.ticketMedioDistribuidor.toFixed(2) }}</span>
                                             </div>
                                        </td>
                                        <td>                                       
                                            <div class="d-flex justify-content-start flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center" style="white-space: nowrap;">{{ "R$ "+item.valorDistribuidor.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                            </div>                                         
                                        </td> 
                                        <td style="border-right: 2px solid #F4DA70">                                         
                                            <div class="d-flex justify-content-start flex-column">	
                                                <span class=" fw-bold  d-block fs-7 text-center" style="white-space: nowrap;">{{ "R$ "+item.faturamentoAnual.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                            </div>                                        
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, toRefs, ref, watch  } from "vue";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { getFaturamentoUltimosMeses, getFaturamento6MesesPdf, getFaturamentoUltimos6Meses } from "@/services/OnePageReportService.ts";
import moment from "moment";
import XLSX from 'xlsx-js-style';
import jsPdf from "jspdf";
import html2canvass from "html2canvas";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { ContentLoader } from 'vue-content-loader';
import usePDF from "@/composables/Pdf";
import FilterModal from "@/layout/header/partials/filters/FilterModal.vue";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";

export default defineComponent({
    name: "FaturamentoUltimosMesesModal",
    props: {
        filtersInicial: Object,
        loading: Boolean,
        metas: Object,
        series: Object,
        temPacote: Boolean,
    },
    components: {
        ContentLoader,
        FilterModal
    },

    setup (props) {
        const store = useStore();
	    const { exportBlob } = usePDF();
		const title = ref("nos últimos 6 meses");
        let animate = ref(true);
        const dados = ref([]);
        const dataInicialPeriodo = moment().startOf("day").subtract(30, 'days').toISOString();
        const dataFInalPeriodo = moment().endOf("day").toISOString();
        const periodoTrintaDias = [dataInicialPeriodo, dataFInalPeriodo]; 
        const datePicker = ref();
		const distribuidor = distribuidorConfig[0]; 
		//chamar as logos das consecionárias
		const loadingExport = ref(false);
        var metas = ref([]);

        const faturamentoDuplicado = [];

        const isCrescimentoFaturamento = ref(false);
        const valueCrescimentoFaturamento = ref(0);
        const isCrescimentoRevisoes = ref(false);
        const valueCrescimentoRevisoes = ref(0);

        
        let porcentagemDaMetaParaAmarelo = 20;
        var metaTMWarning = ref(100);
        var metaConversaoWarning = ref(1000);

        function filtraCategorias(data){
			const filters = trataFilters(trataDataMoment(data[0], data[1]));
			listaFaturamento(filters);
		}

        const aparecerGrafico = ref(false); //variavel q controla o v-if, se ela ta true os graficos sao renderizados novamente
		let bloqueiaGrafico = ref(false);  //criado para o evento de recriar os graficos nao acontecer quando abre o modal de filtro.

        onMounted(()=>{
			//resolver erro de graficos nao aparecendo
            const refModal = document.getElementById("modal_faturamento_last6month");
            refModal.addEventListener('hidden.bs.modal', function(event) {    
                if(!bloqueiaGrafico.value){ 
                    aparecerGrafico.value=false;
				}else{
					bloqueiaGrafico.value = false;
				}
            });
            refModal.addEventListener('show.bs.modal', function(event) {   
                aparecerGrafico.value=true;
            });
        });

        watch(() => props.metas, () => {
           metas.value = props.metas;
        metaTMWarning.value =  parseFloat(props.metas.metaticketmedio) * (1 - (porcentagemDaMetaParaAmarelo/100));
        metaConversaoWarning.value =  parseFloat(props.metas.metaconversao) * (1 - (porcentagemDaMetaParaAmarelo/100));
        });

        watch(datePicker, () => {
            if(datePicker.value == null){
				datePicker.value = periodoTrintaDias;
				return;
			};
    	});

        function trataDataMoment(dataInicial, dataFinal){
            datePicker.value = [dataInicial, dataFinal];
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).format();
            dataInicial = dataInicial.substr(0, 10);
            dataFinal = dataFinal.substr(0, 10);
            
            const filters = `${dataInicial}/${dataFinal}`;
            return filters;
		};

        watch(() => props.metas, () => {
            if(props.filtersInicial){  
                filtradados(props.filtersInicial.data)           
            }
		});

        function filtradados(data) {
            const filters = trataFilters(data);
			listaFaturamento(filters);      
        }
        
        function desabilitaDatasFuturas (data){
            const dataFutura = data.getTime() > Date.now();
            return dataFutura;
        };

        function trataFilters(data) {
            const filters = data;
            data = data.split("/");
            const dataInicial = moment(data[0]);
            const dataFinal = moment(data[1]);
            datePicker.value = [dataInicial, dataFinal];
            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();

            if (!(mesmoAno && mesmoMes)) {
              title.value = `Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
              return filters;
            }

            const diferencaMesFinalAteAtual = Math.round(dataFinal.diff(moment(), 'months', true));
            if (diferencaMesFinalAteAtual == 0) {
              title.value = "nos últimos 6 meses";
            } else {
              title.value = `nos 6 meses antecessores a ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
            };

            const dataFinalFormat = dataFinal.format('YYYY-MM-DD');
            const sixMonths = dataInicial.subtract(5, 'months').format('YYYY-MM-DD');

            return sixMonths + "/" +  dataFinalFormat;
        }
        
		function filtraConsultores(data){
			const filters = trataDataMoment(data[0], data[1]);
			listaConsultores(filters);
		} 

        async function listaFaturamento(filtersData){
            animate.value = true;
            const codConcessionaria = props.filtersInicial.codConcessionaria;
			const responseUltimosMeses = await getFaturamentoUltimosMeses(codConcessionaria, filtersData);
            const response = await getFaturamentoUltimos6Meses(codConcessionaria, filtersData);

            seriesFaturamento.value[0].data = response.map((dado) => {
              return [moment(dado.mesano.substring(0, 4) + "-" + dado.mesano.substring(4) + '-01').unix(), dado.faturamento]
            });
            seriesRevisoes.value[0].data = response.map((dado) => {
              return [moment(dado.mesano.substring(0, 4) + "-" + dado.mesano.substring(4) + '-01').unix(), dado.revisoes]
            });
            const faturamentoDuplicado = [...seriesFaturamento.value[0].data];
            faturamentoDuplicado.unshift(seriesFaturamento.value[0].data[0]);
            faturamentoDuplicado.push(seriesFaturamento.value[0].data[seriesFaturamento.value[0].data.length-1]);

            if(seriesFaturamento.value[0].data.length>0){
                const faturamentoSemZero =  seriesFaturamento.value[0].data.filter(faturamento => faturamento[1] > 0);

                if(faturamentoSemZero.length > 0){
                    const faturamentoInicial = faturamentoSemZero[0][1];
                    const faturamentoFinal = faturamentoSemZero[faturamentoSemZero.length - 1][1];

                    if(faturamentoFinal>faturamentoInicial){
                        isCrescimentoFaturamento.value = true;
                        //formula para calcular % de crescimento = P = 100.(X1 – X0)/X0
                        valueCrescimentoFaturamento.value = (100*((faturamentoFinal - faturamentoInicial)/faturamentoInicial)).toFixed(2).replace(".", ",");
                    }else{
                        isCrescimentoFaturamento.value = false; 
                    }
                } else {
                    isCrescimentoFaturamento.value = false; 
                }
            }
            if(seriesRevisoes.value[0].data.length>0){
                const revisoesSemZero =  seriesRevisoes.value[0].data.filter(revisao => revisao[1] > 0);

                if(revisoesSemZero.length > 0){
                    const revisoesInicial = revisoesSemZero[0][1];
                    const revisoesFinal = revisoesSemZero[revisoesSemZero.length-1][1];
                    if(revisoesFinal>revisoesInicial){
                        isCrescimentoRevisoes.value = true;
                        //formula para calcular % de crescimento = P = 100.(X1 – X0)/X0
                        valueCrescimentoRevisoes.value = (100*((revisoesFinal - revisoesInicial)/revisoesInicial)).toFixed(2).replace(".", ",");
                    }else{
                        isCrescimentoRevisoes.value = false; 
                    }
                } else {
                    isCrescimentoRevisoes.value = false; 
                }
            }
           
			dados.value = responseUltimosMeses;
            animate.value = false;
		}; 

        //inicio conversão tabela html para XLSX
		function exportarXLSX() {
			let wb = XLSX.utils.book_new();
			wb.Props = {
				Title: "SheetJS Tutorial",
				Subject: "Test",
				Author: "Red Stapler",
				CreatedDate: new Date(2017,12,19)
			};
			wb.SheetNames.push("MO");

            	const consultoresTratados = dados.value.map(item =>
				{
                    let auxXLSX = {
                        "Data" : item.dia +"/"+item.mes +"/"+item.ano,
                        "Revisões" :  item.revisoes,
                        "Produtos" :  item.totalProdutosGeral ,
                        "TM" :item.ticketMedioGeral,
                        "Concessionária Total": item.valorTotal.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2})  ,
                        "Não Realizado" :item.valorTotalNaoRealizado.toLocaleString('pt-Br'),
                    };

                    if(props.temPacote){
                        auxXLSX["Pacotes"] = item.pacotes;
                    }

                    auxXLSX["Produtos " + distribuidor.distribuidor.toUpperCase() ] = item.totalProdutosDistribuidor;

                    if(props.temPacote){
                        auxXLSX["Pacotes (%)"] = item.percentualConversaoPacotes;
                        auxXLSX["Pacotes Perdidos"] = item.percentualConversaoPacotesNaoRealizado.toFixed(2);
                    }

					return {
                        ...auxXLSX,
                        ["TM " + distribuidor.distribuidor.toUpperCase() ]: item.ticketMedioDistribuidor,
                        ["Valor " + distribuidor.distribuidor.toUpperCase() ]: item.valorDistribuidor.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2}), 
                        "Faturamento Anual" : item.faturamentoAnual.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2}),
					}
				}	
			)


			const ws = XLSX.utils.json_to_sheet(consultoresTratados);
			const wscols = [
				{wch:45},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
                {wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
			];
            
            ws['!cols'] = wscols;

            if(!props.temPacote){
				wscols.pop();
				wscols.pop();
				wscols.pop();
			} else {
				ws["K1"].s = {
                    fill: {
                        fgColor: { rgb: "FFD6D8DB" },
                    },
                }
                ws["L1"].s = {
                    fill: {
                        fgColor: { rgb: "FFD6D8DB" },
                    },
                }
                ws["M1"].s = {
                    fill: {
                        fgColor: { rgb: "FFD6D8DB" },
                    },
                }
			}

			//mudar a cor do cabeçalho da planilha
			ws["A1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["B1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
             ws["C1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
              ws["D1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["E1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["F1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["G1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["H1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["I1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
            ws["J1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
           
			wb.Sheets["MO"] = ws;
			//mudar a cor do cabeçalho da planilha

            function s2ab(s) { 
                var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
                var view = new Uint8Array(buf);  //create uint8array as viewer
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
                return buf;   
            }

            var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        
            const blob = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
        
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', geraFileName("OnePageReport"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);  
            };
        };      	

        function geraFileName(routeName) {
				const concessionariaName = store.getters.concessionariaSelectedInfo.nomeFantasia;
				const dataAtual = moment().format("YYYY-MM-DD");
				const concessionariaNameTratado = concessionariaName.replaceAll(" ", "_");
				return `${routeName}${dataAtual}${concessionariaNameTratado}.xlsx`
			}
	    //fim da conversão tabela html para XLSX

        const { showTimeAlert } = useAlert();
       	//início da conversão do html em PDF
		async function exportPDF2(data){
            loadingExport.value = true;
            const codConcessionaria = props.filtersInicial.codConcessionaria;
            const dataTratada = trataFilters(trataDataMoment(data[0], data[1]));
            try{
            let response = await getFaturamento6MesesPdf(distribuidor.distribuidor, codConcessionaria, dataTratada);
            exportBlob(response, "OnePageReport", props.filtersInicial.concessionariaName);
            }catch(e){
                showTimeAlert('Não foi possível completar a solicitação', "error");
            }finally{
            loadingExport.value = false;
            }
        };
       
		//fim da conversão do html em PDF



		const chartFaturamento =  ref({
            chart: {
                offsetY: 0,
                fontFamily: "inherit",
                type: "area",

                toolbar: {
                    show: false,
                },
                
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: false,
                },
            },
            colors: ['var(--cor_indicadores_2)'],
            dataLabels: {
                enabled: true,
                offsetY: -5,
                style:{
                  colors: ['var(--cor_texto_1)'],
                  fontSize: '11px',
                  fontWeight: "normal"
                },
                background: {
                  enabled: false,
                },                
                formatter: function (val,  { seriesIndex, dataPointIndex, w }) {
                    if(val===w.config.series[seriesIndex].data[0][1]){
                        return "";
                    }else if(val===w.config.series[seriesIndex].data[w.config.series[seriesIndex].data.length-1][1]){
                        return "";    
                    }else{
                        return  val.toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
                    }
                },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: 'light',
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "var(--cor_indicadores_2)",
                    opacity: 1
                  },
                  {
                    offset: 95,
                    color: "white",
                    opacity: 1
                  },
                ]
              }
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#ffffff',
                        fontSize: "12px",
                    },
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        width: 1,
                        dashArray: 3,
                    },
                },
            },
            yaxis: {            
                labels: {
                    show: true,
                    style: {
                        fontSize: "12px",
                    },
                },
            },
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				floating: true,
				offsetY: -25,
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
            },
            tooltip: {
               enabled:true,
                enabledOnSeries: [0,0],
                style: {
                    fontSize: "12px",
                },
                x: {
                  formatter: function (val) {
                    moment.locale('pt-br');
                    return moment.unix(val).format("MMMM YYYY");
                  },
                },
                y: [
                {
                    formatter: function (val) {
                    return val.toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
                    },
                },
                ],

                marker: {
                    show: false,
                },
            },
            grid:{
              padding: {
                top:10,
            },
            },
            markers: {
                strokeWidth: 5,
            },
        });

	    const chartRevisoes =  ref({
            chart: {
                offsetY: 0,
                fontFamily: "inherit",
                type: "area",

                toolbar: {
                    show: false,
                },
                
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: false,
                },
            },
            colors: ['var(--cor_indicadores_2)'],
            dataLabels: {
                enabled: true,
                offsetY: -5,
                style:{
                  colors: ['var(--cor_texto_1)'],
                  fontSize: '11px',
                  fontWeight: "normal"
                },
                background: {
                  enabled: false,
                },   
                formatter: function (val,  { seriesIndex, dataPointIndex, w }) {
                   if(val===w.config.series[seriesIndex].data[0][1]){
                       return "";
                   }else if(val===w.config.series[seriesIndex].data[w.config.series[seriesIndex].data.length-1][1]){
                       return "";    
                   }else{
                    return  val;
                   }
                }, 
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: 'light',
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "var(--cor_indicadores_2)",
                    opacity: 1
                  },
                  {
                    offset: 95,
                    color: "white",
                    opacity: 1
                  },
                ]
              }
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#ffffff',
                        fontSize: "12px",
                    },
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        width: 1,
                        dashArray: 3,
                    },
                },
            },
            yaxis: {            
                labels: {
                    show: true,
                    style: {
                        fontSize: "12px",
                    },
                },
            },
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				floating: true,
				offsetY: -25,
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                },
                x: {
                  formatter: function (val) {
                    moment.locale('pt-br');
                    return moment.unix(val).format("MMMM YYYY");
                  },
                },


                marker: {
                    show: false,
                },
            },
            grid:{
              padding: {
                top:10,
            },
            },
            markers: {
                strokeWidth: 5,
            },
        });

        const categoriesFaturamento = ref([{
			type: 'string',
			categories: ['', '', '', '', '', '']
		}]);


        const seriesFaturamento = ref([{
            name: 'Faturamento',
            type: 'area',
                data: [[0, 0], [0, 0], [0,0], [0,0], [0,0], [0,0]],
        }]);

        const seriesRevisoes = ref([{
            name: 'Revisões',
            type: 'area',
                data: [[0, 0], [0, 0], [0,0], [0,0], [0,0], [0,0]],
        }]);



        return { 
            ptBr,
            dados,
            exportarXLSX,
            exportPDF2,
            distribuidor,
            filtraConsultores,
            desabilitaDatasFuturas,
            filtraCategorias,
            datePicker,
            animate,
            loadingExport,
            metas,
            filtradados,
            title,
        	chartFaturamento,
			categoriesFaturamento,          
			seriesFaturamento,
            seriesRevisoes,
            chartRevisoes,
            isCrescimentoFaturamento,
            valueCrescimentoFaturamento,
            isCrescimentoRevisoes,
            valueCrescimentoRevisoes,
            aparecerGrafico,
            bloqueiaGrafico,
            metaConversaoWarning,
            metaTMWarning,
        };
    }
});

</script>

<style>
    .calculated-width {
        width: -moz-calc(100% - 100px);
        width: -webkit-calc(100% - 100px);
    }
    
    .bola {
        position: absolute;
        display: block;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        right: -15px;
        top: -30px;
        padding-top: 20px;
        background-color: #27A844;
        font-size:10px;
        font-weight: bold;
        white-space: nowrap;
        
    }
    .d-flex {
    display: none 
    }
</style>
