<template>
  <div class="card card-xxl-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-6 mb-1 cor_texto_1">Faturamento {{ title }}</span>
        <span class="text-muted fw-bold fs-8 cor_texto_2">{{ totalRevisoes }} Revisoes</span>
      </h3>
    </div>
    <!--end::Header-->
    <ContentLoader :animate="true"  viewBox="0 0 310 350" v-if="animate">
      <rect x="26" y="119" rx="5" ry="5" width="40" height="37" /> 
      <rect x="25" y="58" rx="5" ry="5" width="40" height="37" /> 
      <rect x="26" y="177" rx="5" ry="5" width="40" height="37" /> 
      <rect x="166" y="116" rx="5" ry="5" width="40" height="37" /> 
      <rect x="166" y="56" rx="5" ry="5" width="40" height="37" /> 
      <rect x="167" y="178" rx="5" ry="5" width="40" height="37" /> 
      <rect x="193" y="260" rx="5" ry="5" width="77" height="29" /> 
      <rect x="71" y="66" rx="5" ry="5" width="58" height="10" /> 
      <rect x="214" y="185" rx="5" ry="5" width="58" height="10" /> 
      <rect x="72" y="185" rx="5" ry="5" width="58" height="10" /> 
      <rect x="214" y="123" rx="5" ry="5" width="58" height="10" /> 
      <rect x="214" y="62" rx="5" ry="5" width="58" height="10" /> 
      <rect x="73" y="125" rx="5" ry="5" width="58" height="10" />
    </ContentLoader>

    <!--begin::Body-->
    <div class="card-body d-flex flex-column" style="padding-top: 0px" v-if="!animate">
      <div class="scroll h-150px mb-4">
        <div class="row g-0">
          <div class="col-lg-6 col-md-6 col-sm-4 col-6" v-for="item in faturamento" :key="item">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-2 mt-4">
                <div class="symbol-label bg-white bg-opacity-50 border border-dark rounded rounded-3 d-flex flex-column">
                  <span class="fs-8 cor_texto_1">{{ nomesMeses2[item.mes - 1] }}</span>
                  <span class="fs-9 cor_texto_2">{{ item.mesano.substring(0, 4) }}</span>
                </div>
              </div>

              <div>
                <div class="fs-8 fw-bolder lh-1 mt-4 cor_texto_1" style="white-space: nowrap;">R$
                  {{ item.faturamento.toLocaleString('pt-Br', {minimumFractionDigits: 2}) }}
                </div>
                <div class="fs-8  fw-bold cor_texto_2">{{ item.revisoes }} revisões</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-14">
        <div>
          <ul class="ponto">
            <li class="cor_indicadores_2">
              <span class="ms-1" style="color: black">Revisões</span>
            </li>
            <li class="cor_indicadores_1">
              <span class="ms-1" style="color: black">Faturamento</span>
            </li>
          </ul>
        </div>
        <div>
          <a href="#" class="btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1" data-bs-toggle="modal"
            data-bs-target="#modal_faturamento_last6month">Ver mais</a>
        </div>
      </div>
    </div>
    <!--end::Body-->

    <!--begin:Grafico-->
    <div id="chart">
      <apexchart
          :class="`bg-${widgetColor}`"
          class="mixed-widget-2-chart card-rounded-bottom"
          :options="chartFaturamento"
          :series="seriesFaturamento"
          :categories="categoriesFaturamentamento"
          :height="200"
          width="100%"
        >
      </apexchart>
    </div>
    <!--end:Grafico-->


    <FaturamentoUltimosMesesModal :filtersInicial="filters"  :metas="metas" :temPacote="temPacote" />
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {getFaturamentoUltimos6Meses} from "@/services/OnePageReportService.ts" //Função que consome api
import FaturamentoUltimosMesesModal from '@/components/modals/OnePageReportModals/FaturamentoUltimosMesesModal.vue'
import {getCSSVariableValue} from "@/assets/ts/_utils";
import moment from "moment";
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: "FaturamentoUltimos6MesesCard",
  components: {
    FaturamentoUltimosMesesModal,
    ContentLoader
  },
  props: {
    filters: Object,    
    metas: Object,
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
    title: String,
    temPacote: Boolean,
    filtersmodal: Object,
  
  },

  setup(props) {
    // moment.locale('pt-br')
    const labelColor = getCSSVariableValue("--bs-primary");
    const borderColor = getCSSVariableValue("--bs-primary");
    const color = getCSSVariableValue("--bs-" + props.widgetColor);
    const strokeColor = ref(props.strokeColor);
    const title = ref("nos últimos 6 meses");
    let animate = ref(true);

    const chartFaturamento = ref({
      chart: {
        type: 'area',
        fontFamily: "inherit",
        toolbar: {
          show: false,
        },
        grid: {
          show: false
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      colors: ['var(--cor_indicadores_2_opct)', 'var(--cor_indicadores_1)'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 5]
      },
      fill: {
        type: 'solid',
        opacity: [0.35, 1],
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: ['var(--cor_indicadores_1)'],
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: ['var(--cor_indicadores_1)'],
            width: 1,
            dashArray: 3,
          },
        },
      },
      markers: {
        size: 0
      },
      yaxis: [
        {
          labels: {
            show: false,
            style: {
              colors: ['var(--cor_indicadores_1)'],
              fontSize: "12px",
            },
          },
        },
        {
          labels: {
            show: false,
            style: {
              colors: ['var(--cor_indicadores_1)'],
              fontSize: "12px",
            },
          },
        },
      ],
      tooltip: {
        intersect: false,
        shared: true,
        style: {
          fontSize: "12px",
        },
        x: {
          formatter: function (val) {
            moment.locale('pt-br');
            return moment.unix(val).format("MMMM YYYY");
          },
        },
        y: [
          {
            formatter: function (val) {
              return val;
            },
          },
          {
            formatter: function (val) {
              return val.toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
            },
          },
        ],
        marker: {
          show: false,
        },
      }
    });

    const categoriesFaturamentamento = ref([{
      type: 'string',
      categories: ['', '', '', '', '', '']
    }]);

    const faturamento = ref([]);
    const totalRevisoes = ref(0);
    const nomesMeses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const nomesMeses2 = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];


    watch(() => props.filters, () => {
      // 2021-04-01/2021-05-31 É um exemplo com dados
      const filtersData = trataFilters(props.filters.data);
      listaFaturamento(props.filters.codConcessionaria, filtersData);
    });
    


    function trataFilters(data) {
      let filters = data;
      data = data.split("/");
      const dataInicial = moment(data[0]);
      const dataFinal = moment(data[1]);

      const mesmoAno = dataInicial.year() === dataFinal.year();
      const mesmoMes = dataInicial.month() === dataFinal.month();

      if (!(mesmoAno && mesmoMes)) {
        title.value = `Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
        return filters;
      }
      ;

      const diferencaMesFinalAteAtual = Math.round(dataFinal.diff(moment(), 'months', true));
      if (diferencaMesFinalAteAtual == 0) {
        title.value = "nos últimos 6 meses";
      } else {
        title.value = `nos 6 meses antecessores a ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
      }
      ;

      const dataFinalFormat = dataFinal.format('YYYY-MM-DD');
      const sixMonths = dataFinal.subtract(5, 'months').format('YYYY-MM-DD');

      return sixMonths + "/" + dataFinalFormat;
    }

    async function listaFaturamento(codConcessionaria, filtersData) {
      animate.value = true;
      const response = await getFaturamentoUltimos6Meses(codConcessionaria, filtersData);

      faturamento.value = response;

      seriesFaturamento.value[1].data = response.map(mesano => mesano.faturamento);
      seriesFaturamento.value[0].data = response.map(mesano => mesano.revisoes);

      totalRevisoes.value = response.map(faturamentoMes => faturamentoMes.revisoes).reduce((acumulador, valorTotal) => {
        return acumulador + valorTotal
      }, 0);

      geraGrafico(response);
      animate.value = false;
    };

    const seriesFaturamento = ref([{
      name: 'Revisões',
      type: 'area',
      data: [44, 55, 31, 47, 31, 43, 26, 41, 31, 47, 33]
    }, {
      name: 'Faturamento',
      type: 'line',
      data: [55, 69, 45, 61, 43, 54, 37, 52, 44, 61, 43]
    }]);

    function geraGrafico(dados) {
      seriesFaturamento.value[1].data = dados.map((dado) => {
        return [moment(dado.mesano.substring(0, 4) + "-" + dado.mesano.substring(4) + '-01').unix(), dado.faturamento]
      });

      seriesFaturamento.value[0].data = dados.map((dado) => {
        return [moment(dado.mesano.substring(0, 4) + "-" + dado.mesano.substring(4) + '-01').unix(), dado.revisoes]
      });

      if (seriesFaturamento.value[1].data.length === 1 || seriesFaturamento.value[0].data.length === 1) {
        const refSerieIndicadorUnix = seriesFaturamento.value[1].data[0][0] + 10;
        const refSerieIndicadorData = seriesFaturamento.value[1].data[0][1];

        seriesFaturamento.value[1].data.push([refSerieIndicadorUnix, refSerieIndicadorData]);
      }
    }

    return {
      title,
      faturamento,
      nomesMeses,
      totalRevisoes,
      chartFaturamento,
      seriesFaturamento,
      categoriesFaturamentamento,
      nomesMeses2,
      animate
    }
  }
});
</script>

<style>
.ponto > li::marker{
   font-size: 1.5rem;
   vertical-align: bottom;
}

@media (max-width: 1024px)
{
  .card .card-body {
    padding: 1.25rem 0.5rem;
   }
}

</style>