<template>
  <div class="card card-xxl-stretch">
    <!--begin::Header-->
    <div class="card-header card-header-stretch">

      <h6 class="card-title d-flex align-items-center h-55px">
        <span class="card-label fw-bolder fs-5 cor_texto_1">Detalhamento por consultor</span>
      </h6>

      <div class="card-toolbar">
        <ul class="nav nav-tabs nav-line-tabs nav-stretch ">
          <li class="nav-item">
            <a
              class="nav-link active cor_texto_2" :class="animate ? 'disabled' : ''"  data-bs-toggle="tab"  href="#kt_tab_pane_1" id="initial-pane" >Produtos</a
            >
          </li>
          <li class="nav-item" v-if="temPacote">
            <a class="nav-link cor_texto_2" data-bs-toggle="tab" href="#kt_tab_pane_2" :class="animate ? 'disabled' : ''"
              >Pacotes</a
            >
          </li>
          <li class="nav-item" v-if="temPacote">
            <a class="nav-link cor_texto_2" data-bs-toggle="tab" href="#kt_tab_pane_3" :class="animate ? 'disabled' : ''" @click="renderGrafico()"
              >Comparar</a
            >
          </li>
        </ul>
      </div>
    </div>

     <ContentLoader :animate="true"  viewBox=" 0 0 500 288" v-if="animate">
      <rect x="43" y="55" rx="3" ry="3" width="49" height="43" />
      <rect x="107" y="56" rx="3" ry="3" width="89" height="18" />
      <rect x="109" y="82" rx="3" ry="3" width="71" height="10" />
      <rect x="212" y="71" rx="3" ry="3" width="230" height="7" />

      <rect x="43" y="112" rx="3" ry="3" width="49" height="43" />
      <rect x="107" y="113" rx="3" ry="3" width="89" height="18" />
      <rect x="109" y="139" rx="3" ry="3" width="71" height="10" />
      <rect x="212" y="128" rx="3" ry="3" width="230" height="7" />

      <rect x="45" y="170" rx="3" ry="3" width="49" height="43" />
      <rect x="109" y="171" rx="3" ry="3" width="89" height="18" />
      <rect x="111" y="197" rx="3" ry="3" width="71" height="10" />
      <rect x="214" y="186" rx="3" ry="3" width="230" height="7" />

      <rect x="45" y="227" rx="3" ry="3" width="49" height="43" />
      <rect x="109" y="228" rx="3" ry="3" width="89" height="18" />
      <rect x="111" y="254" rx="3" ry="3" width="71" height="10" />
      <rect x="214" y="243" rx="3" ry="3" width="230" height="7" />

    </ContentLoader>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="tab-content" id="myTabContentCard" >
        <div
          class="tab-pane fade show active"
          id="kt_tab_pane_1"
          role="tabpanel"
          v-if="!animate"
        >
          <div v-if="consultores.length == 0" class="cor_texto_2">
				    Nenhuma Informação foi econtrada nesse período
			    </div>
          <div class="scroll pe-8 mb-3" :class="{'h-275px': temPacote || (!temPacote && consultores.length > 3)}">
            <div class="d-flex align-items-center mb-1" v-for="(consultor, i) in consultoresOrdenadoProdutos" :key="i">
              <!--begin::Symbol-->
              <div class="symbol symbol-30 symbol-light-success ps-0 me-5 w-50px">
                <span class="symbol-label">
                  <img :src="consultor.fotoConsultor" class="h-100 align-self-end rounded" alt="">
                </span>
              </div>
              <!--end::Symbol-->

              <!--begin::Item-->
              <div class="d-flex align-items-center mt-n2 mt-lg-n1 w-100">
                <!--begin::Title-->
                <div class="d-flex flex-column my-2 w-100px">
                  <div class="fw-bolder fs-7 mb-1 cor_texto_1">
                    <el-tooltip placement="top"  effect="dark">
                      <template #content>{{consultor.nomeConsultor}}</template>
                      <span>{{ consultor.nomeConsultorTratado }}</span>
                    </el-tooltip>
                  </div>
                  <div class="fw-bold fs-8 cor_texto_2">Consultor (a)</div>
                  <div class="fw-bold fs-8 cor_texto_2" v-if="consultor.premiacao!=''">R$ {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.premiacao)}}</div>
                </div>
                <!--end::Title-->

                <!--begin::Graphic-->
                <div class="flex-grow-1">
                  <div class="progress h-6px w-100">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="`Meta: ${metas.metaticketmedio}`"
                      placement="top"
                    >
                      <div class="progress-mark" :style="`left: ${styleReferenciaMetaProdutos}`"></div>
                    </el-tooltip>
                    <div class="progress-bar" :class="consultor.classBarProdutos" role="progressbar" :style="`width: ${consultor.percentualProdutos}%`" aria-valuemin="0" aria-valuenow="50" aria-valuemax="100">
                    </div>
                  </div>
                  <span class="fs-8 fw-bold mt-4 cor_texto_2">{{ consultor.prodServicosDistribuidor }} vendidos</span>
                </div>
                <!--end::Graphic-->

                <!--begin::Info-->
                <div class="ms-6 mb-8 mt-3 d-flex justify-content-end w-50px">
                  <el-tooltip class="item" effect="dark" placement="top">
                    <template #content>
                    • TM: {{consultor.ticketMedioDistribuidor.toFixed(2)}}<br>
                    • TM Faturamento: R$ {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.faturamentoTicketMedioDistribuidor)}}<br>
                    • Produtos: {{consultor.prodServicosDistribuidor}}<br>
                    • Revisões: {{consultor.revisoes}}<br>
                    • Faturamento do Distribuidor: R$ {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.faturamentoDistribuidor)}}
                    </template>
                    <span class="fw-bold fs-6 cor_texto_2">{{ consultor.ticketMedioDistribuidor.toFixed(2) }}</span>
                  </el-tooltip>
                </div>
                <!--end::Item-->
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel"  v-if="!animate">
          <div v-if="consultores.length == 0" class="cor_texto_2">
				    Nenhuma Informação foi econtrada nesse período
			    </div>
          <div class="scroll h-275px pe-8 mb-3">
            <div class="d-flex align-items-center mb-1" v-for="(consultor, i) in consultoresOrdenadoPacote" :key="i">
              <!--begin::Symbol-->
              <div class="symbol symbol-30 symbol-light-success ps-0 me-5 w-50px">
                <span class="symbol-label">
                   <img :src="consultor.fotoConsultor" class="h-100 align-self-end rounded" alt="">
               </span>
              </div>
              <!--end::Symbol-->

              <!--begin::Item-->
              <div class="d-flex align-items-center mt-n2 mt-lg-n1 w-100">
                <!--begin::Title-->
                <div class="d-flex flex-column my-2 w-100px">
                   <div class="fw-bolder fs-7 mb-1 cor_texto_1">
                    <el-tooltip placement="top"  effect="dark">
                      <template #content>{{consultor.nomeConsultor}}</template>
                      <span>{{ consultor.nomeConsultorTratado }}</span>
                    </el-tooltip>
                  </div>
                  <div class="fw-bold fs-8 cor_texto_2">Consultor (a)</div>
                   <div class="fw-bold fs-8 cor_texto_2" v-if="consultor.premiacao!=''">R$ {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.premiacao)}}</div>
                </div>
                <!--end::Title-->

                <!--begin::Graphic-->
                <div class="flex-grow-1">
                    <div class="progress h-6px w-100">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`Meta: ${metas.metaconversao}%`"
                        placement="top"
                      >
                        <div class="progress-mark" :style="`left: ${styleReferenciaMetaPacotes}`"></div>
                      </el-tooltip>
                      <div class="progress-bar" :class="consultor.classBarPacotes" role="progressbar" :style="`width: ${consultor.percentualPacotes}%`" aria-valuemin="0" aria-valuenow="50" aria-valuemax="100"></div>
                    </div>
                    <span class="fs-8 fw-bold mt-4 cor_texto_2">{{ consultor.pacotes }} vendidos</span>
                </div>
                <!--end::Graphic-->
                <!--begin::Info-->
                <div class="ms-6 mb-8 mt-3 d-flex justify-content-end w-50px">
                  <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>
                        • Conversão Realizada: {{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.ticketMedioPacote * 100)}}%<br>
                        • Pacotes: {{consultor.pacotes}} <br>
                        • Revisões: {{consultor.revisoes}}
                      </template>
                      <span class="cor_texto_2 fw-bold fs-6">{{new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.ticketMedioPacote * 100)}}%</span>
                    </el-tooltip>
                </div>
                <!--end::Item-->
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
          <div v-if="consultores.length == 0" class="cor_texto_2">
				    Nenhuma Informação foi econtrada nesse período
			    </div>

          <div class="h-275px pe-8 mb-4" v-show="consultores.length !== 0 && !hideApex">
            <div id="graficoComparacao"/>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
        <!--begin::Title-->
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
        </div>
        <!--end::Title-->
        <!--begin::Info-->
        <!-- BOTÃO VER MAIS ABRE O MODAL -->
        <div class="text-end py-lg-0 py-2"  v-if="consultores.length > 0">

          <a href="#" class="btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1" data-bs-toggle="modal" data-bs-target="#modal_detalhamento_consultor">Ver mais</a>
        </div>

        <!--end::Info-->
      </div>
    </div>
    <!--end::Body-->
    <ConsultoresDetalhamentoModal :filtersInicial="filters" :metas="metas" :temPacote="temPacote"  />
  </div>

</template>

<style lang="scss">


  .progress {
    position: relative;
    overflow: visible;
  }

  .progress-mark {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    position: absolute;
    margin-top: -1.5px;
    background: var(--cor_indicadores_1);
    border: 2px solid white;
    overflow: visible;
  }

</style>

<script>
import { getIndicadoresConsultores } from "@/services/OnePageReportService";
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import ConsultoresDetalhamentoModal from "../modals/OnePageReportModals/ConsultoresDetalhamentoModal.vue";
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: "ConsultoresCard",

  components: {
	  ConsultoresDetalhamentoModal,
      ContentLoader
  },

  props: {
    filters: Object,
    metas: Object,
    temPacote: Boolean,
  },

  setup(props) {
    const step = ref("produto");
    let animate = ref(true);

    function geraOptions(categories) {
      let yaxis = [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: 'var(--cor_indicadores_2)'
          },
          labels: {
            style: {
              colors: 'var(--cor_indicadores_2)',
            },
          },
          title: {
            text: "Ticket Médio",
            style: {
              color: 'var(--cor_indicadores_2)',
            }
          },
          tooltip: {
            enabled: true
          }
        },   
      ]

      if(props.temPacote){
        yaxis.push({
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: 'var(--cor_indicadores_1)'
          },
          labels: {
            style: {
              colors: 'var(--cor_indicadores_1)',
            },
            formatter: (value) => { return value + "%"},
          },
          title: {
            text: "Conversão de Pacotes",
            style: {
              color: 'var(--cor_indicadores_1)',
            }
          },
        })
      }

      const options = {
        xaxis: {
          labels: {
            rotateAlways: true,
            formatter: (value) => {
              if(value){
              const arrayNomes = value.split(" ");
              return `${arrayNomes[0]} ${arrayNomes[1].substring(0, 1)}`
              }
            },
          },
          categories,
        },
        chart: {
          zoom: {
            enabled: false,
          },
          width: '100%',
          height: '100%',
          series: "line",
          stacked: false,
          toolbar: {
            show: false
          },
        },
        fill: {
          colors: ['var(--cor_indicadores_2)', 'var(--cor_indicadores_1)']
        },
        stroke: {
          show: false
        },
        legend: {
          show: true,
          labels: {
            colors: ['var(--cor_indicadores_2)', 'var(--cor_indicadores_1)']
          },
          markers: {
            colors: ['var(--cor_indicadores_2)', 'var(--cor_indicadores_1)'],
            fillColors: ['var(--cor_indicadores_2)', 'var(--cor_indicadores_1)']
          }
        },
        yaxis
      };

      return options;
    };

    function geraSeries(arrayTicketMedio, arrayPercentualPacotes){
      let series = [
        {
          name: 'Ticket Médio',
          type: 'column',
          data: arrayTicketMedio
        },
      ];

      if(props.temPacote) {
        series.push({
          name: 'Percentual Pacotes',
          type: 'column',
          data: arrayPercentualPacotes
        });
      }

      return series;
    };

    let chartInstance;

    onMounted(async () => {
      const options = geraOptions([]);
      const series = geraSeries([], []);

      chartInstance = new ApexChart(document.querySelector("#graficoComparacao"), {
        ...options,
        series,
      });

      chartInstance.render();
    });

    function renderGrafico(){
      hideApex.value = false;

      setTimeout(() => {
        chartInstance.updateOptions(options.value);
        chartInstance.updateSeries(series.value);
      }, 500)
    }

    watch(() => props.filters, () => {
      animate.value = true;
	  });
    watch(() => props.metas, () => {
      // 2021-04-01/2021-05-31 É um exemplo com dados
      const filtersData = trataFilters(props.filters.data);
      listaConsultores(props.filters.codConcessionaria, filtersData);
	  });

    function trataFilters(data){
      data = data.split("/");
      const dataInicial = data[0].substring(0, 10);
      const dataFinal = data[1].substring(0, 10);
      return `${dataInicial}/${dataFinal}`;
    };

    function geraStyleMeta (valor, maiorValor){
      if (valor >= maiorValor){
        return "calc(100% - 8px)";
      };

      const style = ((valor / maiorValor) * 100).toFixed(1);
      return `calc(${style}% - 8px)`;
    };

    const consultores = ref([]);
    const styleReferenciaMetaProdutos = ref("");
    const styleReferenciaMetaPacotes = ref("");
    const options = ref();
    const series = ref();
    const hideApex = ref(true);

    const consultoresOrdenadoPacote = ref([]);
    const consultoresOrdenadoProdutos = ref([]);

    async function listaConsultores(codConcessionaria, filtersData) {

      hideApex.value = true;
      animate.value = true;

      const response = await getIndicadoresConsultores(codConcessionaria, filtersData);
      consultores.value = [];

      response.forEach(dadoMes => {
        dadoMes.forEach((consultor) => {
          const consultorRepetido = consultores.value.some(dado => dado.nomeConsultor == consultor.nomeConsultor);
          if(consultorRepetido){
            const refConsultor = consultores.value.find(dado => dado.nomeConsultor == consultor.nomeConsultor);
            refConsultor.pacotes += consultor.pacotes;
            refConsultor.prodServicosGeral += consultor.prodServicosGeral;
            refConsultor.prodServicosDistribuidor += consultor.prodServicosDistribuidor;
            refConsultor.faturamentoTicketMedioDistribuidor += consultor.faturamentoTicketMedioDistribuidor;
            refConsultor.faturamentoDistribuidor += consultor.faturamentoDistribuidor;
            refConsultor.revisoes += consultor.revisoes;
            refConsultor.premiacao += consultor.premiacao;
          } else {

            var contador = 0;
            var primeiroNome = "";
            var sigla = "";
            const tratandoNomes = consultor.nomeConsultor.split(" ", 3);

            tratandoNomes.forEach(element => {
              if (contador == 0) {
                  primeiroNome = element + " ";
              } else {
                  if (element.length > 2 && element[0].match(/[a-z]/i) ){
                      sigla += element[0] + ".";
                  }
              }
              contador++;
            });

            const nomeFormatado = primeiroNome + sigla;

            consultores.value.push(
              {
                nomeConsultorTratado: nomeFormatado,
                nomeConsultor: consultor.nomeConsultor,
                fotoConsultor: consultor.fotoConsultor,
                pacotes: consultor.pacotes,
                prodServicosGeral: consultor.prodServicosGeral,
                prodServicosDistribuidor: consultor.prodServicosDistribuidor,
                faturamentoTicketMedioDistribuidor: consultor.faturamentoTicketMedioDistribuidor,
                faturamentoDistribuidor: consultor.faturamentoDistribuidor,
                revisoes: consultor.revisoes,
                ticketMedioDistribuidor: 0,
                ticketMedioPacote: 0,
                premiacao : consultor.premiacao,
              }
            );
          };
        });
      });

      consultores.value.forEach((consultor) => {
        const ticketMedio = consultor.prodServicosDistribuidor / consultor.revisoes;
        const ticketMedioPacote = (consultor.pacotes / consultor.revisoes) > 1 ? 1 : consultor.pacotes / consultor.revisoes;

        consultor.ticketMedioDistribuidor = ticketMedio && ticketMedio != Infinity ? ticketMedio : 0;
        consultor.ticketMedioPacote = ticketMedioPacote && ticketMedioPacote != Infinity ? ticketMedioPacote : 0;
      });

      const arrayConsultores = consultores.value.map(consultor => consultor.nomeConsultorTratado);

      const arrayTicketMedio = consultores.value.map(consultor => consultor.ticketMedioDistribuidor.toFixed(2));
      const arrayPercentualPacotes = consultores.value.map(consultor => {
        return (consultor.ticketMedioPacote * 100).toFixed(2);
      });

      let maiorTicketMedio = Math.max(...arrayTicketMedio);
      maiorTicketMedio = maiorTicketMedio > props.metas.metaticketmedio ? maiorTicketMedio : props.metas.metaticketmedio;

      let maiorPercentualPacotes = Math.max(...arrayPercentualPacotes);
      maiorPercentualPacotes = maiorPercentualPacotes > props.metas.metaconversao ? maiorPercentualPacotes : props.metas.metaconversao;

      let porcentagemDaMetaParaAmarelo = 20;

      consultores.value.forEach((consultor) => {
        consultor.percentualProdutos= ((consultor.ticketMedioDistribuidor.toFixed(2) / maiorTicketMedio) * 100).toFixed(1);
        if(Number(consultor.ticketMedioDistribuidor.toFixed(2)) >= props.metas.metaticketmedio){
          consultor.classBarProdutos = 'cor1';
        } else if (Number(consultor.ticketMedioDistribuidor.toFixed(2)) >= props.metas.metaticketmedio * (1-(porcentagemDaMetaParaAmarelo/100))){
          consultor.classBarProdutos = 'cor3';
        } else {
          consultor.classBarProdutos = 'cor4';
        };
        if(Number((consultor.ticketMedioPacote * 100).toFixed(2)) >= props.metas.metaconversao){
          consultor.classBarPacotes = 'cor1';
        } else if (Number((consultor.ticketMedioPacote * 100).toFixed(2)) >= props.metas.metaconversao * (1-(porcentagemDaMetaParaAmarelo/100))){
          consultor.classBarPacotes = 'cor3';
        } else {
          consultor.classBarPacotes = 'cor4';
        };

        consultor.percentualPacotes = (((consultor.ticketMedioPacote * 100).toFixed(2) / maiorPercentualPacotes) * 100).toFixed(1);
      });

      styleReferenciaMetaProdutos.value = geraStyleMeta(props.metas.metaticketmedio, maiorTicketMedio);
      styleReferenciaMetaPacotes.value = geraStyleMeta(props.metas.metaconversao, maiorPercentualPacotes);

      options.value = geraOptions(arrayConsultores);
      series.value = geraSeries(arrayTicketMedio, arrayPercentualPacotes);

      chartInstance.updateOptions(options.value);
      chartInstance.updateSeries(series.value);

      consultoresOrdenadoPacote.value = consultoresOrdenado(true);
      consultoresOrdenadoProdutos.value = consultoresOrdenado(false);
      animate.value = false;
    };

    function consultoresOrdenado(isPacote){
      var aux = [];
      if(isPacote){
          return consultores.value.sort((a, b) => { return b.ticketMedioPacote - a.ticketMedioPacote;});
      }else{
          aux = [...consultores.value];
          return aux.sort((a, b) => { return b.ticketMedioDistribuidor - a.ticketMedioDistribuidor;});
      }
    }

    return {
      consultores,
      step,
      styleReferenciaMetaProdutos,
      styleReferenciaMetaPacotes,
      renderGrafico,
      animate,
      hideApex,
      consultoresOrdenadoPacote,
      consultoresOrdenadoProdutos,

    };
  }
});

</script>

<style scoped>

.cor1 {
  background-color: var(--cor_indicadores_1)
}
.cor3 {
  background-color: var(--cor_indicadores_3)
}
.cor4 {
  background-color: var(--cor_indicadores_4)
}

</style>