

import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import { getGraficosParticipacaoPacotes } from "@/services/OnePageReportService";
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader'

export default defineComponent({
  name: "ConversaodePacotesCard",
  components: {
    ContentLoader
  },
  props: {
		loadingPercentual: Boolean,
    
    filters: Object,

    percentualConversaoPacotes: String,

    cardClasses: String,

    avatar: String,

    online: Boolean,

    initials: String,

    color: String,

    name: String,

    position: String,

    avgEarnings: String,

    totalSales: String,
  },
  
  setup(props) {

    const data = reactive({
      quantidadeSemPacote: 0,
      quantidadepacotebasico: 0,
      quantidadepacoteintermediario: 0,
      quantidadepacoterecomendado: 0,  
      somatotalpacotes: 0,
      somapercentualsempacote: 0,
      somapercentualbasico: 0,
      somapercentualintermediario: 0,
    });

  
    let somapercentualsempacote = ref({});
    let somapercentualbasico = ref ({});
    let somapercentualintermediario = ref ({});
    let somapercentualrecomendado = ref ({});

    let nomePacoteFabrica = ref("");
    let nomePacoteBasico = ref ("");
    let nomePacoteIntermediario = ref ("");
    let nomePacoteRecomendado = ref ("");

    let series = ref([0, 0, 0, 0]);
    let animate = ref(true);

    const isIOS = ref(false);

    function geraOptions() {
      const newOptions = {
        dataLabels: {
          enabled: false,
        },
        chart: {
          width: '100%',
          height: '230',
          type: 'donut',
          options: "chart",
          series: "series",
          offsetY: -20
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,      
        },
        plotOptions: {
          pie: {
            customScale: 0.95,
            donut: {
              size: '80%'
            }
          }
        },
        labels: [nomePacoteRecomendado.value, nomePacoteIntermediario.value, nomePacoteBasico.value, nomePacoteFabrica.value],
        colors: ['var(--cor_indicadores_1)', 'var(--cor_indicadores_2)', 'var(--cor_indicadores_3)', 'var(--cor_indicadores_4)'],
        fill: {
          colors:  ['var(--cor_indicadores_1)', 'var(--cor_indicadores_2)','var(--cor_indicadores_3)', 'var(--cor_indicadores_4)']
        },
        legend: {
          show:false,
        },
      }

      return newOptions;
    }
      
    //declaração de variaveis//
    const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";
    
    let chartInstance;
    onMounted(async () => {
       var userAgent = navigator.userAgent || navigator.vendor;
       var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      //  console.log(isSafari);    
       
      if( isSafari )
      {
        isIOS.value = true;
      }
      const options = geraOptions();
      chartInstance = new ApexChart(document.querySelector("#graficoPacotes"), {
        ...options,
        series: [0, 0, 0, 0],
      });
      chartInstance.render();
    });

    watch(() => props.filters, () => {
      // 2021-04-01/2021-05-31 É um exemplo com dados
      listaGraficoConversoesPacotes(props.filters?.codConcessionaria, props.filters?.data);
    });


    async function listaGraficoConversoesPacotes(codConcessionaria, filtersData){
      animate.value = true;
      const response = await getGraficosParticipacaoPacotes(codConcessionaria, filtersData);

      data.quantidadeSemPacote = 0;
      data.quantidadepacotebasico = 0;
      data.quantidadepacoteintermediario = 0;
      data.quantidadepacoterecomendado = 0;

      somapercentualbasico.value = 0;
      somapercentualsempacote.value = 0;
      somapercentualintermediario.value = 0;
      somapercentualrecomendado.value = 0;
      // console.log("response gráfocp 12-", response);
      if(!response[0] && !response[1]){
        chartInstance.updateSeries([0, 0, 0, 0]);
        animate.value = false;

        return;
      };
      await response.forEach(item => {
        if(!item)
          return;
        data.quantidadeSemPacote += item.sem_pacotes;
        data.quantidadepacotebasico += item.pacote_basico;
        data.quantidadepacoteintermediario += item.pacote_intermediario;
        data.quantidadepacoterecomendado += item.pacote_recomendado;
        nomePacoteFabrica.value = item.nome_pacote_fabrica;
        nomePacoteBasico.value = item.nome_pacote_basico;
        nomePacoteIntermediario.value = item.nome_pacote_intermediario;
        nomePacoteRecomendado.value = item.nome_pacote_recomendado;
      });

      const totalPacotes = data.quantidadeSemPacote + data.quantidadepacotebasico + data.quantidadepacoteintermediario + data.quantidadepacoterecomendado;
      
      if(totalPacotes>0){
          somapercentualbasico.value = ((data.quantidadepacotebasico*100)/ totalPacotes).toFixed(1);
          somapercentualsempacote.value = ((data.quantidadeSemPacote*100)/ totalPacotes).toFixed(1);
          somapercentualintermediario.value = ((data.quantidadepacoteintermediario*100)/ totalPacotes).toFixed(1);
          somapercentualrecomendado.value = ((data.quantidadepacoterecomendado*100)/ totalPacotes).toFixed(1);
      }
      series.value = [data.quantidadepacoterecomendado, data.quantidadepacoteintermediario, data.quantidadepacotebasico, data.quantidadeSemPacote];
      chartInstance.updateSeries(series.value);
      chartInstance.updateOptions({
                            labels: [nomePacoteRecomendado.value, nomePacoteIntermediario.value, nomePacoteBasico.value, nomePacoteFabrica.value],
                          });
      animate.value = false;

    }

    return {
      ...toRefs(data),
      animate,
      image,
      series,
      somapercentualsempacote,
      somapercentualbasico,
      somapercentualintermediario,
      somapercentualrecomendado,
      nomePacoteFabrica,
      nomePacoteBasico,
      nomePacoteIntermediario,
      nomePacoteRecomendado,
      isIOS,
    };
  },
  

});
