
import { getVendasProdutosConsultor, getVendasCategoriasConsultor, getPDFProdutosMaisVendidos, getPDFProdutosServicosPdf } from "@/services/OnePageReportService";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { defineComponent, onMounted, ref, toRefs, reactive, watch } from "vue";
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import moment from 'moment';
import XLSX from 'xlsx-js-style';
import jsPdf from "jspdf";
import html2canvass from "html2canvas";
import { ContentLoader } from 'vue-content-loader';
import usePDF from '@/composables/Pdf';
import useAlert from "@/composables/Alert";
import FilterModal from "@/layout/header/partials/filters/FilterModal.vue";
import { useStore } from "vuex";

//const host = 'makena.inservice.app';
//chamar as logos das consecionárias
export default defineComponent ({
    name: "ProdutosMaisVendidosModal",
     props: {
	   filtersInicial: Object,
	   title: String,
     },
	 components: {
		 ContentLoader,
		 FilterModal
	 },
    setup(props) {
		const store = useStore();
		const distribuidor = distribuidorConfig[0]; 

		const title = ref("");
		const { exportBlob } = usePDF();
        const dataInicialPeriodo = moment().startOf("day").subtract(30, 'days').toISOString();
        const dataFInalPeriodo = moment().endOf("day").toISOString();

        const periodoTrintaDias = [dataInicialPeriodo, dataFInalPeriodo];        
        const datePicker = ref();
        const animate = ref(true);
        const loadingExport = ref(false);
        const loadingExport2 = ref(false);

		watch(() => props.filtersInicial, () => {
			if(props.filtersInicial){
				const filters = trataData(props.filtersInicial?.data);
				listaDadosCategoria(filters);
				listaDadosProdutos(filters);
			}
		});

		function filtraCategorias(data){
			const filters = trataDataMoment(data[0], data[1]);

			listaDadosCategoria(filters);
			listaDadosProdutos(filters);
		}

		 function filtradados(data) {
            const filters = trataData(data);
			listaDadosCategoria(filters);
			listaDadosProdutos(filters);
        }

		watch(datePicker, () => {
			if(datePicker.value == null){
				datePicker.value = periodoTrintaDias;
				return;
			};
    	});
        const { showTimeAlert } = useAlert();
		//início da conversão do html em PDF
		async function baixarpdf(data, tipo){
				const codConcessionaria = props.filtersInicial?.codConcessionaria;
               	const dataTratada = trataDataMoment(data[0], data[1]);
               	var response;
				try{
					if(tipo=="categoria"){
						loadingExport.value = true;
						response = await getPDFProdutosMaisVendidos(distribuidor.distribuidor, codConcessionaria, dataTratada);
					}else{
						loadingExport2.value = true;
						response = await getPDFProdutosServicosPdf(distribuidor.distribuidor, codConcessionaria, dataTratada);
					}
      				exportBlob(response, "OnePageReport");
				}catch(e){
                showTimeAlert('Não foi possível completar a solicitação', "error");
           		 }finally{
					loadingExport.value = false;
					loadingExport2.value = false;
				}
		}

    	const itemsCategoria:any = ref([]);
    	const arrayNomesConsultoresCategoria:any = ref([]);
    	const arrayNomesConsultoresCategoriaTratados:any = ref([]);

    	const itemsProduto:any = ref([]);
    	const arrayNomesConsultoresProduto:any = ref([]);
    	const arrayNomesConsultoresProdutoTratados:any = ref([]);
	

		async function listaDadosProdutos(filtersData){
			animate.value = true;
			const codConcessionaria = props.filtersInicial?.codConcessionaria;
			itemsProduto.value = [];
			arrayNomesConsultoresProduto.value = [];
			arrayNomesConsultoresProdutoTratados.value = [];
			
	
			const response = await getVendasProdutosConsultor(codConcessionaria, filtersData);
			const tabela = await getVendasProdutosConsultor(codConcessionaria, filtersData)

			const emptyArray:any = [];

			const dadosProdutos:any = []
			var revisoesTotal = 0;
			response.forEach(dadoProdutoMes => {
				revisoesTotal += dadoProdutoMes[0]["revisoes"];
				dadoProdutoMes.forEach(dadoProduto => {
					const consultorRepetido = arrayNomesConsultoresProduto.value.some((nome) => nome === dadoProduto.nomeConsultor);
					if(!consultorRepetido){
						arrayNomesConsultoresProduto.value.push(dadoProduto.nomeConsultor);
					};

					const refDado = dadosProdutos.find((dado) => dado.nomeServico === dadoProduto.nomeServico);
					if(refDado){
						refDado.quantidadeTotal += Number(dadoProduto.qtd);
					} else {
						const refDistribuidor = distribuidor.distribuidor === "localhost" || distribuidor.distribuidor === "homologacao" ? `${distribuidor.distribuidor}_dark` : distribuidor.distribuidor;
						dadosProdutos.push(
							{	
								ticketmedio: dadoProduto.ticketmedio,
								nomeServico: dadoProduto.nomeServico,
								fornecedor: dadoProduto.fornecedor,
								nomeCategoria: dadoProduto.nomeCategoria,
								codCategoria: dadoProduto.codCategoria,
								revisoes: dadoProduto.revisoes,
								revisoesTotaisPorConsultor: dadoProduto.revisoesTotaisPorConsultor,
								quantidadeTotal: Number(dadoProduto.qtd),
								penetracaoProduto: 0,
								consultores: [...emptyArray],
								src: dadoProduto.fornecedor == "1" && dadoProduto.codCategoria == 1 ? `./distribuidor/icone_${refDistribuidor}.png` : `./img_categoria/Categoria_${dadoProduto.codCategoria}.svg`
							}
						)
					}
				});

			});
			// tratar array para exportar XLSX						
			arrayNomesConsultoresProduto.value.forEach((nome) =>{
				dadosProdutos.forEach((produto) => {
					produto.penetracaoProduto = (produto.quantidadeTotal / revisoesTotal) * 100;

					const arrayNomeConsultor = nome.split(" ");
					const nomeTratado = `${arrayNomeConsultor[0]} ${arrayNomeConsultor[1]?.substring(0, 0)  ?? ""}`;

					// Encontrar o dadoProduto correspondente ao nome do consultor
					const dadoProduto = response.flat().find(dp => dp.nomeConsultor === nome);
					const revisoesTotaisPorConsultor = dadoProduto ? dadoProduto.revisoesTotaisPorConsultor : 0;


					const consultor = {
						nome,
						nomeTratado,
						quantidade: 0,
						revisoesTotal: revisoesTotal,
						revisoesTotaisPorConsultor: revisoesTotaisPorConsultor,
					}
					produto.consultores.push(consultor);
				})
			});
			
			response.forEach(dadoProdutoMes => {
				dadoProdutoMes.forEach(dadoProduto => {
					const refDado = dadosProdutos.find(dado => dado.nomeServico === dadoProduto.nomeServico);
					if(refDado){
						const refConsultor = refDado.consultores.find(dadoConsultor => dadoConsultor.nome === dadoProduto.nomeConsultor);

						if(refConsultor){
							refConsultor.quantidade += Number(dadoProduto.qtd);
						}
					} else {
						
					}
				});
			});

			dadosProdutos.sort((produtoA, produtoB) => {
				if (produtoA.fornecedor < produtoB.fornecedor) {
					return -1;
				}

				if (produtoA.fornecedor == produtoB.fornecedor) {
					if (produtoA.codCategoria < produtoB.codCategoria) {
    					return -1;
  					}

					if (produtoA.codCategoria > produtoB.codCategoria) {
    					return 1;
  					}
  				}		

				if (produtoA.fornecedor > produtoB.fornecedor) {
					return 1;
				}

				// a deve ser igual a b
				return 0;
			}),

			itemsProduto.value = dadosProdutos;

			arrayNomesConsultoresProdutoTratados.value = arrayNomesConsultoresProduto.value.map(nome => {
				const arrayNomeConsultor = nome.split(" ");
				const nomeTratado = `${arrayNomeConsultor[0]} ${arrayNomeConsultor[1]?.substring(0, 0) ?? ""}`;

				var contador = 0;
				var primeiroNome = "";
				var sigla = "";
				const tratandoNomes = nome.split(" ", 3);
           	    tratandoNomes.forEach(element => {
            	  if (contador == 0) {
                  primeiroNome = element + " ";
              	} else {
                  if (element.length > 2 && element[0].match(/[a-z]/i) ){
                      sigla += element[0] + ".";
                }
              }
              contador++;

			return  primeiroNome + sigla
            });
		
			return nomeTratado;
			
			})
			

			animate.value = false;
		}

		async function listaDadosCategoria(filtersData){
			/* animate.value = true; */
			const codConcessionaria = props.filtersInicial?.codConcessionaria;
			itemsCategoria.value = [];
			arrayNomesConsultoresCategoria.value = [];
			arrayNomesConsultoresCategoriaTratados.value = [];

			const response = await getVendasCategoriasConsultor(codConcessionaria, filtersData);
			const emptyArray:any = [];

			const dadosCategoria:any = [];

			response.forEach(dadoCategoriaMes => {
				dadoCategoriaMes.forEach(dadoCategoria => {
					const consultorRepetido = arrayNomesConsultoresCategoria.value.some((nome) => nome === dadoCategoria.nome);
					if(!consultorRepetido){
						arrayNomesConsultoresCategoria.value.push(dadoCategoria.nome);
					};

					const refDado = dadosCategoria.find((dado) => dado.descricao === dadoCategoria.descricao);
					if(refDado){
						refDado.quantidadeTotal += Number(dadoCategoria.quantidade);
						refDado.valorTotal += Number(dadoCategoria.valor);
					}else{
							if(dadoCategoria.fornecedor == "1" && dadoCategoria.codCategoria == "1"){
								const refDistribuidor = distribuidor.distribuidor === "localhost" || distribuidor.distribuidor === "homologacao" ? `${distribuidor.distribuidor}_dark` : distribuidor.distribuidor;

								var newCategoria = {
									ticketMedio: dadoCategoria.ticketMedio,
									descricao: dadoCategoria.descricao,
									fornecedor: dadoCategoria.fornecedor,
									codCategoria: dadoCategoria.codCategoria,
									quantidadeTotal: Number(dadoCategoria.quantidade),
									valorTotal: Number(dadoCategoria.valor),
									consultores: [...emptyArray],
									src: `./distribuidor/icone_${refDistribuidor}.png`
								};
							}else{
									var newCategoria = {
										ticketMedio: dadoCategoria.ticketMedio,
										descricao: dadoCategoria.descricao,
										fornecedor: dadoCategoria.fornecedor,
										codCategoria: dadoCategoria.codCategoria,
										quantidadeTotal: Number(dadoCategoria.quantidade),
										valorTotal: Number(dadoCategoria.valor),
										consultores: [...emptyArray],
										src: `./img_categoria/Categoria_${dadoCategoria.codCategoria}.svg`
									};
							}
							dadosCategoria.push(newCategoria);

					}
				});
			});

			

			arrayNomesConsultoresCategoria.value.forEach((nome) =>{
				dadosCategoria.forEach((categoria) => {
					const arrayNomeConsultor = nome.split(" ");
					const nomeTratado = `${arrayNomeConsultor[0]} ${arrayNomeConsultor[1]?.substring(0, 0)  ?? ""}`;

					const consultor = {
						nome,
						nomeTratado,
						quantidade: 0,
						valor: 0,
					}
					categoria.consultores.push(consultor);
				})
			});

			response.forEach(dadoProdutoMes => {
				dadoProdutoMes.forEach(dadoProduto => {
					const refDado = dadosCategoria.find(dado => dado.descricao === dadoProduto.descricao);
					if(refDado){
						const refConsultor = refDado.consultores.find(dadoConsultor => dadoConsultor.nome === dadoProduto.nome);

						if(refConsultor){
							refConsultor.quantidade += Number(dadoProduto.quantidade);
							refConsultor.valor += Number(dadoProduto.valor);
						}
					}	
				});
			});

			itemsCategoria.value = dadosCategoria;


			arrayNomesConsultoresCategoriaTratados.value = arrayNomesConsultoresCategoria.value.map(nome => {
           	const arrayNomeConsultor = nome.split(" ");
				const nomeTratado = `${arrayNomeConsultor[0]} ${arrayNomeConsultor[1]?.substring(0, 0) ?? ""}`;
				

				var contador = 0;
				var primeiroNome = "";
				var sigla = "";
				const tratandoNomes = nome.split(" ", 3);
           	    tratandoNomes.forEach(element => {
            	  if (contador == 0) {
                  primeiroNome = element + " ";
              	} else {
                  if (element.length > 2 && element[0].match(/[a-z]/i) ){
                      sigla += element[0] + ".";
                }
              }
              contador++;

			return  primeiroNome + sigla
            });
		
			return nomeTratado;
			
			})
		
		}

		
		function trataData(data){
			let filters = data;
			data = data.split("/");
			const dataInicial = moment(data[0]);
			const dataFinal = moment(data[1]);
			const mesmoAno = dataFinal.year() === dataInicial.year();
			const mesmoMes = dataFinal.month() === dataInicial.month();
			datePicker.value = [dataInicial, dataFinal];
			if (mesmoAno && mesmoMes) {
				title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
				return filters;
			}else{
				title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
				return filters;
			}
		}

		function trataDataMoment(dataInicial, dataFinal){
			datePicker.value = [dataInicial, dataFinal];
			dataInicial = moment(dataInicial).format();
			dataFinal = moment(dataFinal).format();

			dataInicial = dataInicial.substr(0, 10);
			dataFinal = dataFinal.substr(0, 10);
			

			const filters = `${dataInicial}/${dataFinal}`;

			return filters;
		};
	
		function desabilitaDatasFuturas (data){
			const dataFutura = data.getTime() > Date.now();
			return dataFutura;
		};

		const alfabeto = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

		function achaNomeColuna(indexConcultor){
			const indexColunaInicial = 1 + ((indexConcultor) * 2)
			const indexColunaFinal = indexColunaInicial + 1;


			return {colunaInicial: alfabeto[indexColunaInicial], colunaFinal: alfabeto[indexColunaFinal], indexColunaInicial, indexColunaFinal};
			// 26
		};
		function achaNomeColuna2(indexConcultor){
			const indexColunaInicial = 1 + indexConcultor;
			const indexColunaFinal = indexColunaInicial + 1;


			return {colunaInicial: alfabeto[indexColunaInicial], colunaFinal: alfabeto[indexColunaFinal], indexColunaInicial, indexColunaFinal};
			// 26
		};


		function geraTabela(){
			const indexItem = 0;
			const arrayConsultores = [
				{
					nome: "Rogerio D",
					qtd: "247",
					valor: "4"
				},
				{
					nome: "Maria M",
					qtd: "247",
					valor: "4"
				},
				{
					nome: "Leandro R",
					qtd: "247",
					valor: "4"
				}
			];

			const ws = XLSX.utils.json_to_sheet([]);		
			ws["A1"] = {
				v: "Categoria",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};
			ws["A2"] = {
				v: "",
				t: "s",
			};
			ws["A3"] = {
				v: "Agregados Locahost",
				t: "s",
			};
			ws["A4"] = {
				v: "Alinhamento",
				t: "s",
			};
			ws["A5"] = {
				v: "Agregados",
				t: "s",
			};
			ws["A6"] = {
				v: "Estética",
				t: "s",
			};

			const merges = [{ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }]
			let lastCell = "";

			arrayNomesConsultoresCategoriaTratados.value.forEach((consultor, index) => {
				const { colunaInicial, colunaFinal, indexColunaInicial, indexColunaFinal } = achaNomeColuna(index);

				ws[`${colunaInicial}1`] = {
					v: consultor,
					t: "s",
					s: {
						fill: {
							fgColor: {
								rgb: "FFD6D8DB"
							},
							bgColor: {
								indexed: "64"
							}
						},
					},
				};
				
				ws[`${colunaInicial}2`] = {
					v: "Qtd",
					t: "s",
					s: {
						fill: {
							fgColor: {
								rgb: "FFD6D8DB"
							},
							bgColor: {
								indexed: "64"
							}
						},
					},
				};

				itemsCategoria.value.forEach((categoria, indexCategoria) => {
					const indexTratado = indexCategoria + 3;
					const refConsultor = categoria.consultores.find(consultorCategoria => consultorCategoria.nomeTratado == consultor);
					const quantidadeConsultor = refConsultor.quantidade;
					const valorConsultor = refConsultor.valor;

					ws[`${colunaInicial}${indexTratado}`] = {
						v: quantidadeConsultor,
						t: "s",
					};

					ws[`${colunaFinal}${indexTratado}`] = {
						v: valorConsultor,
						t: "s",
					};
					
					const refLastColuna = achaNomeColuna(arrayConsultores.length);
					const colunaInicialTotal = refLastColuna.colunaInicial;
					const colunaFinalTotal = refLastColuna.colunaFinal;

					ws[`${colunaInicialTotal}${indexTratado}`] = {
						v: categoria.quantidadeTotal,
						t: "s",
						s: {
							fill: {
								fgColor: {
									rgb: "FFD6D8DB"
								},
								bgColor: {
									indexed: "64"
								}
							},
						},
					};
					ws[`${colunaFinalTotal}${indexTratado}`] = {
						v: categoria.valorTotal,
						t: "s",
						s: {
							fill: {
								fgColor: {
									rgb: "FFD6D8DB"
								},
								bgColor: {
									indexed: "64"
								}
							},
						},
					};
				});
				
				ws[`${colunaFinal}1`] = {
					v: "",
					t: "s",
				};
				ws[`${colunaFinal}2`] = {
					v: "Valor",
					t: "s",
					s: {
						fill: {
							fgColor: {
								rgb: "FFD6D8DB"
							},
							bgColor: {
								indexed: "64"
							}
						},
					},
				};
	
				merges.push(
					{ s: { r: 0, c: indexColunaInicial }, e: { r: 0, c: indexColunaFinal } }
				)
			})

			const { colunaInicial, colunaFinal } = achaNomeColuna(arrayConsultores.length);
			

			ws[`${colunaInicial}1`] = {
				v: "Total",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};
			ws[`${colunaFinal}1`] = {
				v: "",
				t: "s",
			};
			ws[`${colunaInicial}2`] = {
				v: "Qtd",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};
			ws[`${colunaFinal}2`] = {
				v: "Valor",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};

			lastCell = `${colunaFinal}6`;
			
			const wscols = [
				{wch:45},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
			]

			ws["!merges"] = merges;
			ws['!cols'] = wscols;
			ws['!ref'] = `A1:${lastCell}`;

			return ws;
		};

		
		function geraTabelaB(){
			const indexItem = 0;

			const ws = XLSX.utils.json_to_sheet([]);		
			 ws["A1"] = {
				v: "PRODUTO",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};
				 
			let lastCell = "";

			arrayNomesConsultoresProdutoTratados.value.forEach((consultor, index) => {
				const { colunaInicial, indexColunaInicial, colunaFinal, indexColunaFinal} = achaNomeColuna2(index);

				ws[`${colunaInicial}1`] = {
					v: consultor,
					t: "s",
					s: {
						fill: {
							fgColor: {
								rgb: "FFD6D8DB"
							},
							bgColor: {
								indexed: "64"
							}
						},
					},
				};
										
				itemsProduto.value.forEach((produto, indexproduto) => {
					const indexTratado = indexproduto + 2;
					const refConsultor = produto.consultores.find(consultorProduto => consultorProduto.nomeTratado == consultor);
					const quantidadeConsultor = refConsultor.quantidade;
					const valorConsultor = refConsultor.valor;

					ws[`${colunaInicial}${indexTratado}`] = {
						v: quantidadeConsultor,
						t: "s",
					};

					ws[`A${indexTratado}`] = {
						v: produto.nomeServico,
						t: "s",
					} 	
					
					const refLastColuna = achaNomeColuna2(arrayNomesConsultoresProdutoTratados.value.length);
					const colunaInicialTotal = refLastColuna.colunaInicial;
					const colunaFinalTotal = refLastColuna.colunaFinal;

					ws[`${colunaInicialTotal}${indexTratado}`] = {
						v: produto.quantidadeTotal,
						t: "s",
					};

					ws[`${colunaFinalTotal}${indexTratado}`] = {
						v:  produto.penetracaoProduto.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2}) ,
						t: "s",
					};
				});	
			
			})

			const { colunaInicial, colunaFinal } = achaNomeColuna2(arrayNomesConsultoresProdutoTratados.value.length);		

			ws[`${colunaInicial}1`] = {
				v: "TOTAL",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};
							
			ws[`${colunaFinal}1`] = {
				v: "PENETRAÇÃO DE VENDA DO PRODUTO",
				t: "s",
				s: {
					fill: {
						fgColor: {
							rgb: "FFD6D8DB"
						},
						bgColor: {
							indexed: "64"
						}
					},
				},
			};

			lastCell = `${colunaFinal}100`;
			
			const wscols = [
				{wch:45},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
			]


			ws['!cols'] = wscols;
			ws['!ref'] = `A1:${lastCell}`;

			return ws;
		};

	//inicio conversão tabela html para XLSX
		function exportarXLSX() {
			let wb: XLSX.WorkBook = XLSX.utils.book_new();
			wb.Props = {
				Title: "SheetJS Tutorial",
				Subject: "Test",
				Author: "Red Stapler",
				CreatedDate: new Date(2017,12,19)
			};
			wb.SheetNames.push("MO");

			const tabela = geraTabela();

			wb.Sheets["MO"] = tabela;
			//mudar a cor do cabeçalho da planilha
		
		function s2ab(s) { 
			var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf);  //create uint8array as viewer
			for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
			return buf;    
      };

	  //fim da exportação do XLSX
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

      const blob = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
      
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', geraFileName("OnePageReport"));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  
      };
    };
	//fim da conversão tabela html para XLSX	  
	function geraFileName(routeName) {
		const concessionariaName = store.getters.concessionariaSelectedInfo.nomeFantasia;
		const dataAtual = moment().format("YYYY-MM-DD");
		const concessionariaNameTratado = concessionariaName.replaceAll(" ", "_");
		return `${routeName}${dataAtual}${concessionariaNameTratado}.xlsx`
	}
	
	//inicio conversão tabela html para XLSX
		function exportarXLSX2() {
			let wb: XLSX.WorkBook = XLSX.utils.book_new();
			wb.Props = {
				Title: "SheetJS Tutorial",
				Subject: "Test",
				Author: "Red Stapler",
				CreatedDate: new Date(2017,12,19)
			};
			wb.SheetNames.push("MO");

			const tabela = geraTabelaB();

			wb.Sheets["MO"] = tabela;
			//mudar a cor do cabeçalho da planilha
		
		function s2ab(s) { 
			var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf);  //create uint8array as viewer
			for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
			return buf;    
      };

	  //fim da exportação do XLSX
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

      const blob = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
      
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', geraFileName("OnePageReport"));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  
      };
    };
	//fim da conversão tabela html para XLSX	
		const consultores:any = ref([]);

		return { consultores, datePicker, desabilitaDatasFuturas, ptBr, animate, itemsCategoria, itemsProduto, filtraCategorias, arrayNomesConsultoresCategoriaTratados, arrayNomesConsultoresProdutoTratados, exportarXLSX, distribuidor, baixarpdf , exportarXLSX2, loadingExport, loadingExport2,title, filtradados };
	}
})
        
