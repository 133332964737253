
    import { defineComponent, onMounted, ref, watch } from 'vue';

    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import { useRoute } from 'vue-router';
    import { getConcessionarias } from '@/services/GeralService';
    import { hideModal } from '@/core/helpers/dom';
    import { getPDFOnePageReportCompleto, getPDFOnePageReportSimplificado } from '@/services/OnePageReportService';
    import distribuidorConfig from "@/core/config/DistribuidorConfig";
    import usePDF from '@/composables/Pdf'
    import { Modal } from "bootstrap";
    import AuthService from "@/services/AuthService";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import ApiService from '@/services/ApiService';

    export default defineComponent({
        name: "filtro",
        components: {
            ElConfigProvider,
        },
        emits: ["filtrarModal"],

        props: {
            id: {
                type: String,
                default: "filter-modal"
            },
            filtersInicial: String,
        },

        setup(props, { emit }) {
            watch(() => props.filtersInicial, () => {
                if(props.filtersInicial){
                    const arrayData = props.filtersInicial.split("/");
                    datePicker.value = arrayData;

                    emiteMudanca(datePicker.value);
                }
            });

            const { exportBlob } = usePDF();
            const emitter = useEmitter();
            const route = ref(useRoute());
            const userInfo = AuthService.getUsuarioAuth();
            
            const dataMesAtual = moment().startOf("day").toISOString();
            // const dataInicialPeriodo = moment().startOf("day").subtract(30, 'days').toISOString();
            // const dataFInalPeriodo = moment().endOf("day").toISOString();
           
            
            // const periodoTrintaDias = [dataInicialPeriodo, dataFInalPeriodo];
            const periodoTrintaDias = [dataMesAtual, dataMesAtual];

            let existeFiltroCache;
            let valorInicial;

            if(window.localStorage.getItem("id_usuario"))
            {
                existeFiltroCache = window.sessionStorage.getItem("filtroData") && window.sessionStorage.getItem("filtroData") != 'null';
                valorInicial = existeFiltroCache ? window.sessionStorage.getItem("filtroData")?.split(",") : periodoTrintaDias;
            }
            else
            {
                existeFiltroCache = window.sessionStorage.getItem("filtroData") && window.sessionStorage.getItem("filtroData") != 'null';
                valorInicial = existeFiltroCache ? window.sessionStorage.getItem("filtroData")?.split(",") : periodoTrintaDias;
            }
            
            const datePicker:any = ref(valorInicial);
            const lastEmitIsNull = ref(false);
        	const loading = ref(false);
            const modalFiltro:any = ref();


            onMounted(()=>{
                /* const refModal = document.getElementById(props.id);
                    if(refModal){
                    document.getElementById("layout_content")?.appendChild(refModal);
                }  */
            })

             function openModal(){
                const refModal = document.getElementById(props.id);
                modalFiltro.value = new Modal(refModal);

                modalFiltro.value.show();
            }

            function retornaFiltroData(dataInicial, dataFinal){
                dataInicial = moment(dataInicial).format();
                dataFinal = moment(dataFinal).endOf('month').format();
                dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
                dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

                const filters = `${dataInicial}/${dataFinal}`;
                
                return filters;
            };

            async function emiteMudanca(valorData){
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();
                /////
                if(valorData == null){
                    if(lastEmitIsNull.value){
                        return;
                    };
                    
                    lastEmitIsNull.value = true;
                    emit("filtrarModal",retornaFiltroData(periodoTrintaDias[0], periodoTrintaDias[1]));
                    return;
                };

                lastEmitIsNull.value = false;
                const dataTratada = retornaFiltroData(valorData[0], valorData[1]);
                emit("filtrarModal", {data: dataTratada});
            };

            watch(datePicker, () => {
                if(datePicker.value == null){
                    datePicker.value = periodoTrintaDias;
                    return;
                };
            });

            
            function desabilitaDatasFuturas (data){
                const dataFutura = data.getTime() > Date.now();
                return dataFutura;
            };

            
            function desabilitaDatasAnteriores (data){
                const dataAnterior = data.getTime() > new Date("2016-01-01T00:00:00") === data.getTime() > Date.now()
                return dataAnterior
            }

            function verificaIntervalo(data){
                var diff = moment(data[1]).diff(moment(data[0]), "months");
                if(diff<=24){
                    emiteMudanca(datePicker.value); 
                    modalFiltro.value.hide()
                }else{
                   Swal.fire({
                        title: "Período inválido!",
                        html: `<h4>Não é possível filtrar um período superior a 24 meses (2 anos)!</h4>`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok",
                        customClass: {
                        confirmButton: "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
                        },
                    });
                }
            }

            return {
                openModal,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                periodoTrintaDias, 
                lastEmitIsNull, 
                route, 
                desabilitaDatasFuturas, 
                desabilitaDatasAnteriores,
                verificaIntervalo,
            };
        }
    });
