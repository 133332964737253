

<template>
  <!-- <div
      class="btn-icon btn-help background_btn_1" @click="openModal()"
    >
    <span class="svg-icon svg-icon-white svg-icon-2x">
      <inline-svg src="media/icons/duotune/general/star.svg" />
    </span>
  </div> -->
  <div class="modal fade" id="modal-carrousel-card" tabindex="-1" v-if="themeDistribuidor !== 'stellantis'" >
    <div class="d-flex justify-content-center">
      <div class="modal-dialog" id="modal-carrousel-dialog" :style="{width: height + 'px'}">
        <div class="modal-content" style="border-radius:15px;">
          <div class="d-flex">
            <div id="carouselExampleIndicators" class="carousel slide">
              <div class="carousel-indicators" style="border-radius: 15px;" id="indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="indicadorItem active"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" class="indicadorItem"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" class="indicadorItem"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" class="indicadorItem"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" class="indicadorItem"></button>
              </div>
              <div class="carousel-inner" style="border-radius: 15px" id="items">
                <div class="carousel-item active">
                  <img :src="`./distribuidor/carousel/stepper_${themeDistribuidor}_1.png`" class="d-block w-100" alt="..." style="background-color:black;" >
                </div>
                <div class="carousel-item">
                  <img :src="`./distribuidor/carousel/stepper_${themeDistribuidor}_2.png`" class="d-block w-100" alt="..." style="background-color:black;">
                </div>
                <div class="carousel-item">
                  <img :src="`./distribuidor/carousel/stepper_${themeDistribuidor}_3.png`" class="d-block w-100" alt="..." style="background-color:black;">
                </div>
                <div class="carousel-item">
                  <img :src="`./distribuidor/carousel/stepper_${themeDistribuidor}_4.png`" class="d-block w-100" alt="..." style="background-color:black;">
                </div>
                <div class="carousel-item" @click="verificadorCarrousel = 2;modalCarrousel.hide();" style="cursor: pointer">
                  <img :src="`./distribuidor/carousel/stepper_${themeDistribuidor}_5.png`" class="d-block w-100" alt="..." style="background-color:black;">
                </div>
              </div>
              <a class="carousel-control-prev" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style="margin-top: 150px;">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </a>
              <a class="carousel-control-next" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style="margin-top: 150px;">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </a>
            </div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" style="position: absolute; right: 0; width: 50px; height: 50px"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, onDeactivated, onActivated } from "vue";
import { Modal, Carousel } from "bootstrap";
import useEmitter from '@/composables/Emmiter'
import AuthService from "@/services/AuthService";
//import DemoComponent from "./components/DemoComponent.vue"

  export default defineComponent({
    setup(){
      const themeDistribuidor = document.documentElement.getAttribute('theme');
      const emitter = useEmitter();
      const modalCarrousel = ref();
      const carrousel = ref();
      const vuemodal = ref(null);
      var height = ref(300);
      const userInfo = AuthService.getUsuarioAuth();
      const token = userInfo.token;
      var verificadorCarrousel = ref(0); //variavel que verifica se é para aparecer o carroussel ou não.

      if(token){
        emitter.on("showHelp",() => {
          if(!window.localStorage.getItem("verificadorCarrousel")){
            window.localStorage.setItem('verificadorCarrousel', 0);
          } else{
          verificadorCarrousel.value = window.localStorage.getItem("verificadorCarrousel");
          }
          verificadorCarrousel.value< 3 ? openModal(false) : console.log("nao existe verificador") ;
          emitter.off("showHelp");
        });
      }

      function openModal(staticModal = false){

        const myCarousel = document.querySelector('#carouselExampleIndicators');

        carrousel.value = new Carousel(myCarousel, {
          interval: 5000,
          ride: 'carousel',
          wrap: false
        })

        carrousel.value.to(0);

        height.value = (window.innerHeight/2);

        const refModal = document.getElementById("modal-carrousel-card");
        if(refModal){
          document.body.appendChild(refModal);
        }
        if(staticModal){
          modalCarrousel.value = new Modal(refModal, {keyboard: false, backdrop: 'static'});

        } else {
          modalCarrousel.value = new Modal(refModal);

        }

      refModal.addEventListener('hidden.bs.modal', function(event) {
        verificadorCarrousel.value++;
          window.localStorage.setItem('verificadorCarrousel', verificadorCarrousel.value);
      });

        modalCarrousel.value.show();
      };

      return {openModal, modalCarrousel, height, verificadorCarrousel, themeDistribuidor}
    }
  })
</script>

<style lang="scss">
  .btn-help {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    background-color: var(--cor_btn_3);
    border-radius: 0.475rem;
    opacity: 0.3;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
    /*
    opacity: 0;
    transition: color 0.2s ease, background-color 0.2s ease;
    */
  }

  @media (max-width: 991px) {
    .btn-help {
      width: 30px;
      height: 30px;
      right: 15px;
      bottom: 70px;
    }
  }

  @media (min-width: 992px) {
    .btn-help {
      width: 36px;
      height: 36px;
      right: 20px;
      bottom: 85px;
    }
  }
  .carousel-inner>.item {
    -webkit-transition: 0s !important;
    -o-transition: 0s !important;
    transition: 0s !important;
}

</style>  
