
import {getIndicadoresConsultores, getInformacoesPorConsultorPdf } from "@/services/OnePageReportService";
import { defineComponent, onMounted, ref, toRefs, reactive, watch } from "vue";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import moment from 'moment';
import ApexChart from "apexcharts";
import XLSX from 'xlsx-js-style';
import jsPdf from "jspdf";
import html2canvass from "html2canvas";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { ContentLoader } from 'vue-content-loader';
import usePDF from "@/composables/Pdf";
import { array } from "yup/lib/locale";
import FilterModal from "@/layout/header/partials/filters/FilterModal.vue";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";

//import { getConsultoresDetalhamento } from "@/modals/OnepageReportModals/ConsultoresDetalhamentoModal";
//função que consome a API

export default defineComponent ({
	name: "ConsultoresDetalhamentoModal",
	props: {
		filtersInicial: Object,
		metas: Object,
		clicou: Object,
		loading: Boolean,
		title: String,
		temPacote: Boolean
	},
	components: {
		ContentLoader,
		FilterModal
	},

	setup(props) {
		const store = useStore();

		const activeName = ref('first');

				const title = ref("")
				const { exportBlob } = usePDF();
				const dataInicialPeriodo = moment().startOf("day").subtract(30, 'days').toISOString();
				const dataFInalPeriodo = moment().endOf("day").toISOString();

				let porcentagemDaMetaParaAmarelo = 20;
				var metaConversaoWarning = ref(40);
				var metaTMWarning = ref(4);

				const emptyArray:any = [];
				const periodoTrintaDias = [dataInicialPeriodo, dataFInalPeriodo];
				const datePicker = ref(emptyArray);
				let animate = ref(true);
				const step = ref("produto");
				const loadingExport = ref(false);
				var metas:any = [];
				//chamar as logos das consecionárias
				const distribuidor = distribuidorConfig[0];

				const { showTimeAlert } = useAlert();
				async function exportPDF(data) {
					loadingExport.value = true;
					const codConcessionaria = props.filtersInicial?.codConcessionaria;
					const concessionariaName = props.filtersInicial?.concessionariaName;
					const dataTratada = trataDataMoment(data[0], data[1]);
					try{
						let response = await getInformacoesPorConsultorPdf(distribuidor.distribuidor, codConcessionaria, dataTratada);
						
						exportBlob(response, "OnePageReport");
					}catch(e){
                		showTimeAlert('Não foi possível completar a solicitação', "error");
            		}finally{
						loadingExport.value = false;
					}
				};


				//início da construção do gráfico 1-  ticket medio apexcharts//
			function geraOptions(categories, max) {
					const options2 = {
						annotations: {
							yaxis: [
								{
								y: metas['metaticketmedio'],
		
								borderColor: 'red',
								strokeDashArray: 0,
								offsetX: -1,
								width: '110%',
									label: {
										style: {
										color: '#fff',
										borderRadius: 10,
										background: '#f64e60'
										},
										text: 'Meta = ' + metas['metaticketmedio'],
										textAnchor: 'end',
										position: 'right'
									}
								}
							]
					},
				
					colors: [function({ value, seriesIndex, w }) {
						if (value >= metas['metaticketmedio']) {
							return 'var(--cor_indicadores_1)'
						} else if(value>=metas['metaticketmedio'] * (1-(porcentagemDaMetaParaAmarelo/100))){
						return 'var(--cor_indicadores_3)';
					} else {
							return 'var(--cor_indicadores_4)'
						}
						}],
					plotOptions: {
							bar: {
								borderRadius: 10,
								columnWidth: "20%",
								endingShape: 'rounded',
								dataLabels: {
									position: 'top',
								}
							},
					},
					grid: {
						clipMarkers: false,
							yaxis: {
								lines: {
									show: false,
								}
							}
					},
					dataLabels: {
						enabled: true,
					formatter: function (val) {
					const valueFormatter = val && !isNaN(val) ? val : 0;
					return valueFormatter ;
					},
					offsetY: -20,
					style: {
						fontSize: '12px',
						colors: ['var(--cor_texto_1)']
					}
					},
					xaxis: {
					labels: {
						borderRadius: 200,
						formatter: (value) => {
						if(value){
							const arrayNomes = value.split(" ");
							if(arrayNomes.length>1){
								return `${arrayNomes[0]} ${arrayNomes[1].substring(0, 1)}`
							}
							return value;
						}
						},
						position: 'top',
						style: {
							colors: 'var(--cor_texto_1)',
						},
						rotateAlways: true,
					},

					categories,
					},
					
					
					chart: {
						redrawOnParentResize: true,
						type: "bar",
					zoom: {
					enabled: false,
					},
					width: '100%',
					height: '100%',
					series: "bar",
					stacked: true,
					toolbar: {
						show: false,
					},
					},
					yaxis: [
						{
							max: max,
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: ['var(--cor_texto_1)']
						},

						labels: {
							style: {
							colors: ['var(--cor_texto_1)']
							},
						},
						title: {
							text: "Ticket Médio Produtos",
							style: {
							color: ['var(--cor_texto_1)']
							}
						},
						tooltip: {
							enabled: false,
						}
						},
					],
				};

				return options2;
			};			

				//início da construção do gráfico 2-  ticket medio apexcharts//
			function geraOptions2(categories) {
			

				const options2 = {
					annotations: {
						yaxis: [
							{
								y: metas['metaconversao'],
							borderColor: 'red',
							strokeDashArray: 0,
							offsetX: -1,
							width: '110%',
								label: {
									style: {
									color: '#fff',
									borderRadius: 10,
									background: '#f64e60'
									},
									text: 'Meta = ' + metas['metaconversao']+'%',
									textAnchor: 'end',
									position: 'right'
								}
							}
						]
				},
				colors: [function({ value, seriesIndex, w }) {
					if (value >= metas['metaconversao']) {
						return 'var(--cor_indicadores_1)'
					} else if(value>=metas['metaconversao'] * (1-(porcentagemDaMetaParaAmarelo/100))){
						return 'var(--cor_indicadores_3)';
					}else{
						return 'var(--cor_indicadores_4)'
					}
					}],
				plotOptions: {
						bar: {
							borderRadius: 10,
							columnWidth: "20%",
							endingShape: 'rounded',
							dataLabels: {
								position: 'top',
							}
						},
				},
				dataLabels: {
					enabled: true,
				formatter: function (val) {
				const valueFormatter = val && !isNaN(val) ? val : 0;
				return valueFormatter + "%";
				},
				offsetY: -20,
				style: {
					fontSize: '12px',
					colors: ['var(--cor_texto_1)']
				}
				},
				xaxis: {
				labels: {
					borderRadius: 200,
					formatter: (value) => {
					if(value){
						const arrayNomes = value.split(" ");
						if(arrayNomes.length>1){
							return `${arrayNomes[0]} ${arrayNomes[1].substring(0, 1)}`
						}
					}
					return value;
					},
					position: 'top',
					style: {
						colors: 'var(--cor_texto_1)',
					},
						rotateAlways: true,
				},
				categories,
				},
				grid: {
					clipMarkers: false,
						yaxis: {
							lines: {
								show: false,
							}
						}
				},

				chart: {
					redrawOnParentResize: true,
					type: "bar",
				zoom: {
				enabled: false,
				},
				width: '100%',
				height: '100%',
				series: "bar",
				stacked: true,
				toolbar: {
					show: false,
				},
				},
				yaxis: [
					{
					max: 100,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: ['var(--cor_texto_1)']
					},

					labels: {
						style: {
						colors: ['var(--cor_texto_1)']
						},
					},
					title: {
						text: "Conversão de Pacotes",
						style: {
						color: ['var(--cor_texto_1)']
						}
					},
					tooltip: {
						enabled: false,
					}
					},
				],
				};

				return options2;
			};
				//fim da construção do gráfico 1-  ticket medio apexcharts//

				//ínicio da 1º Função para chamar dados no gráfico
			function geraSeries(arrayTicketMedio){
			

				const series = [
					{
					type: 'bar',
					name: "Ticket Médio Produtos",
					data: arrayTicketMedio,

					},
					
				]
				

				return series;
			};
			//fim da 1º Função para chamar dados no gráfico
			//ínicio da 2º Função para chamar dados no gráfico
			function geraSeries2(arrayPercentualPacotes){
			

				const series2 = [
					{
					type: 'bar',
					name: "Conversão de Pacotes",
					data: arrayPercentualPacotes,
					},
				]
				return series2;
			};
			//Fim da 2º Função para chamar dados no gráfico

			let chartInstance;
			let chartInstance2;
			let options; 
			let series;
			let options2;
			let series2;

			let bloqueiaGrafico = ref(false);  //criado para o evento de recriar os graficos nao acontecer quando abre o modal de filtro.

		


			onMounted(async () => {	

				chartInstance = null;
				chartInstance2 = null;
				options = null; 
				series = null;
				options2 = null;
				series2 = null;

				let bloqueiaConstrucao = false;
				const refModal:any = document.getElementById("modal_detalhamento_consultor");
				refModal.addEventListener('hidden.bs.modal', function(event) { 
					if(!bloqueiaGrafico.value){   
						chartInstance.destroy();
						chartInstance2.destroy();						
						chartInstance = null;
						chartInstance2 = null;
						bloqueiaConstrucao = false;
					}else{
						bloqueiaGrafico.value = false;
					}
				});
				refModal.addEventListener('show.bs.modal', function(event) {   
					
					if(!bloqueiaConstrucao){  
						chartInstance = new ApexChart(document.querySelector("#graficoComparacao2"), {
							...options,
							series,
						});          
						chartInstance.render();



						chartInstance2 = new ApexChart(document.querySelector("#graficoComparacao3"), {
							...options2,
							series,
						});
						chartInstance2.render();
						chartInstance2.updateSeries(series2);
						bloqueiaConstrucao = true;

					}else{
						
					}


						
				});

			});

			watch(() => props.metas, () => {
				if(props.filtersInicial){  
					filtradados(props.filtersInicial.data)           
				}
				metaTMWarning.value =  parseFloat(props.metas?.metaticketmedio) * (1 - (porcentagemDaMetaParaAmarelo/100));
				metaConversaoWarning.value =  parseFloat(props.metas?.metaconversao) * (1 - (porcentagemDaMetaParaAmarelo/100));
			});
			watch(datePicker, () => {
				if(datePicker.value == null){
					datePicker.value = periodoTrintaDias;
					return;
				};
			});

			function filtradados(data) {
				const filters = trataData(data);
				listaConsultores(filters);
			}
			
			function trataData(data){
				let filters = data;
				data = data.split("/");
				const dataInicial = moment(data[0]);
				const dataFinal = moment(data[1]);
				const mesmoAno = dataFinal.year() === dataInicial.year();
				const mesmoMes = dataFinal.month() === dataInicial.month();
				datePicker.value = [dataInicial, dataFinal];
				if (mesmoAno && mesmoMes) {
					title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
					return filters;
				}else{
					title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
					return filters;
				}
			}

			function trataDataMoment(dataInicial, dataFinal){
				datePicker.value = [dataInicial, dataFinal];
				dataInicial = moment(dataInicial).format();
				dataFinal = moment(dataFinal).format();
				dataInicial = dataInicial.substr(0, 10);
				dataFinal = dataFinal.substr(0, 10);

				const filters = `${dataInicial}/${dataFinal}`;

				return filters;
			};



			//inicio conversão tabela html para XLSX
			function exportarXLSX() {
				let wb: XLSX.WorkBook = XLSX.utils.book_new();
				wb.Props = {
					Title: "SheetJS Tutorial",
					Subject: "Test",
					Author: "Red Stapler",
					CreatedDate: new Date(2017,12,19)
				};
				wb.SheetNames.push("MO");

				const consultoresTratados = consultores.value.map(consultor => {
					let auxXLSX:any = {
						"Nome do Consultor" : consultor.nomeConsultorTratado,
						"Revisões" : new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.revisoes),
						"Serviços" : new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.prodServicosGeral),
						"TM" : consultor.ticketMedioGeral.toFixed(2),
					};

					
					auxXLSX["Produto/Serviços"] =  new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.prodServicosDistribuidor);

					if(props.temPacote) {
						auxXLSX["Pacotes Total"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.pacotes);
					};
					
					if(props.temPacote) {
						auxXLSX["Conversão Total"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.prodconversaoPacotesDistribuidor)+"%";
					};

					if(props.temPacote) {
						auxXLSX["Pacotes Básico"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.pacotesBasico);
					};
					
					if(props.temPacote) {
						auxXLSX["Conversão Básico"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.prodconversaoPacotesBasicoDistribuidor)+"%";
					};

					if(props.temPacote) {
						auxXLSX["Pacotes Intermediário"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.pacotesIntermediario);
					};
					
					if(props.temPacote) {
						auxXLSX["Conversão Intermediário"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.prodconversaoPacotesIntermediarioDistribuidor)+"%";
					};

					if(props.temPacote) {
						auxXLSX["Pacotes Recomendado"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(consultor.pacotesCompleto);
					};
					
					if(props.temPacote) {
						auxXLSX["Conversão Recomendado"] = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.prodconversaoPacotesCompletoDistribuidor)+"%";
					};

					auxXLSX = { 
						...auxXLSX,
						["TM  " + distribuidor.distribuidor.toUpperCase()]: consultor.ticketMedioDistribuidor.toFixed(2),
						"TM Valor" : new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.faturamentoTicketMedioDistribuidor),
						["Agregados " + distribuidor.distribuidor.toUpperCase()]:  new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(consultor.faturamentoDistribuidor),
					}

					return auxXLSX;
				});

				const ws = XLSX.utils.json_to_sheet(consultoresTratados);
				const wscols = [
					{wch:45},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
				];

				if(!props.temPacote){
					wscols.pop();
					wscols.pop();
				} else {
					ws["I1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["J1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["K1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["L1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["M1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["N1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["O1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}

					ws["P1"].s = {
						fill: {
							fgColor: { rgb: "FFD6D8DB" },
						},
					}
				}

				//mudar a cor do cabeçalho da planilha
				ws['!cols'] = wscols;
				ws["A1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["B1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["C1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["D1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["E1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["F1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["G1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				ws["H1"].s = {
					fill: {
					fgColor: { rgb: "FFD6D8DB" },
					},
				}
				
				wb.Sheets["MO"] = ws;
				//mudar a cor do cabeçalho da planilha

					function s2ab(s) {
						var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
						var view = new Uint8Array(buf);  //create uint8array as viewer
						for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
						return buf;
					};

				//fim da exportação do XLSX
				var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

				const blob = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});

				const link = document.createElement('a');
				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);
					link.setAttribute('href', url);
					link.setAttribute('download', geraFileName("OnePageReport"));
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				};
			};

			function geraFileName(routeName) {
				const concessionariaName = store.getters.concessionariaSelectedInfo.nomeFantasia;
				const dataAtual = moment().format("YYYY-MM-DD");
				const concessionariaNameTratado = concessionariaName.replaceAll(" ", "_");
				return `${routeName}${dataAtual}${concessionariaNameTratado}.xlsx`
			}
			//fim da conversão tabela html para XLSX
			//inicio da compilação de dados!-->

			const data = reactive({
				quantidaderevisoes: 0,
				quantidadepacotebasico: 0,
				quantidadepacoteintermediario: 0,
				quantidadepacoterecomendado: 0,
				somatotalpacotes: 0,
				somarevisoes: 0,
				somaPacotes: 0,
				somaPacotesBasico: 0,
				somaPacotesIntermediario: 0,
				somaPacotesCompleto: 0,
				somaProdServicosDistribuidor: 0,
				somaprodServicosGeral: 0,
				somaticketMedioGeral: 0,
				somafaturamentoTicketMedioDistribuidor:0,
				somaprodconversaoPacotesDistribuidor:0,
				somaprodconversaoPacotesBasicoDistribuidor:0,
				somaprodconversaoPacotesIntermediarioDistribuidor:0,
				somaprodconversaoPacotesCompletoDistribuidor:0,
				somafaturamentoDistribuidor: 0,
				somaticketMedioDistribuidor: 0,
			});

			const consultores:any = ref([]);

			async function listaConsultores(filtersData){
				animate.value = true;

				const codConcessionaria = props.filtersInicial?.codConcessionaria;

				const response = await getIndicadoresConsultores(codConcessionaria, filtersData);

				metas = props.metas;
				data.quantidaderevisoes = 0;
				data.quantidadepacotebasico = 0;
				data.quantidadepacoteintermediario = 0;
				data.quantidadepacoterecomendado = 0;
				data.somatotalpacotes = 0;
				data.somarevisoes = 0;
				data.somaPacotes = 0;
				data.somaPacotesBasico = 0;
				data.somaPacotesIntermediario = 0;
				data.somaPacotesCompleto = 0;
				data.somaProdServicosDistribuidor = 0;
				data.somaprodServicosGeral = 0;
				data.somaticketMedioGeral = 0;
				data.somafaturamentoTicketMedioDistribuidor = 0;
				data.somaprodconversaoPacotesDistribuidor = 0;
				data.somaprodconversaoPacotesBasicoDistribuidor = 0;
				data.somaprodconversaoPacotesIntermediarioDistribuidor = 0;
				data.somaprodconversaoPacotesCompletoDistribuidor = 0;
				data.somafaturamentoDistribuidor = 0;
				data.somaticketMedioDistribuidor = 0;
				consultores.value = [];

				response.forEach(dadoMes => {
					dadoMes.forEach((consultor) => {
						const consultorRepetido = consultores.value.some(dado => dado.nomeConsultor == consultor.nomeConsultor);
						if(consultorRepetido){
							const refConsultor = consultores.value.find(dado => dado.nomeConsultor == consultor.nomeConsultor);
							refConsultor.revisoes += consultor.revisoes;
							refConsultor.pacotes += consultor.pacotes;
							refConsultor.pacotesBasico += consultor.pacotesBasico;
							refConsultor.pacotesIntermediario += consultor.pacotesIntermediario;
							refConsultor.pacotesCompleto += consultor.pacotesCompleto;
							refConsultor.prodServicosDistribuidor += consultor.prodServicosDistribuidor;
							refConsultor.prodServicosGeral += consultor.prodServicosGeral;
							refConsultor.faturamentoTicketMedioDistribuidor += consultor.faturamentoTicketMedioDistribuidor;
							refConsultor.faturamentoDistribuidor += consultor.faturamentoDistribuidor;
						} else{

								var contador = 0;
								var primeiroNome = "";
								var sigla = "";
								const tratandoNomes = consultor.nomeConsultor.split(" ", 3);

								tratandoNomes.forEach(element => {
								if (contador == 0) {
									primeiroNome = element + " ";
								} else {
									if (element.length > 2 && element[0].match(/[a-z]/i) ){
										sigla += element[0] + ".";
									}
								}
								contador++;
								});

								const nomeFormatado = primeiroNome + sigla;


							consultores.value.push(
								{
									nomeConsultor: consultor.nomeConsultor,
									revisoes: consultor.revisoes,
									prodServicosGeral: consultor.prodServicosGeral,
									ticketMedioGeral:0,
									pacotes: consultor.pacotes,
									pacotesBasico: consultor.pacotesBasico,
									pacotesIntermediario: consultor.pacotesIntermediario,
									pacotesCompleto: consultor.pacotesCompleto,
									ticketMedioDistribuidor: 0,
									conversaoPacotesDistribuidor: 0, 
									ticketMedioPacote: 0,
									prodServicosDistribuidor: consultor.prodServicosDistribuidor,
									faturamentoTicketMedioDistribuidor: consultor.faturamentoTicketMedioDistribuidor,
									faturamentoDistribuidor: consultor.faturamentoDistribuidor,
									nomeConsultorTratado: nomeFormatado,

								}
							)
								
						}
						
						
						data.somarevisoes += consultor.revisoes;
						data.somafaturamentoDistribuidor += consultor.faturamentoDistribuidor
						data.somaprodServicosGeral += consultor.prodServicosGeral
						data.somaPacotes += consultor.pacotes;
						data.somaPacotesBasico += consultor.pacotesBasico;
						data.somaPacotesIntermediario += consultor.pacotesIntermediario;
						data.somaPacotesCompleto += consultor.pacotesCompleto;
						data.somaProdServicosDistribuidor += consultor.prodServicosDistribuidor;
						data.somafaturamentoTicketMedioDistribuidor += consultor.faturamentoTicketMedioDistribuidor;

					})

				})
				data.somaticketMedioGeral = (data.somaprodServicosGeral / data.somarevisoes);
				data.somaprodconversaoPacotesDistribuidor= (((data.somaPacotes / data.somarevisoes)<1?(data.somaPacotes / data.somarevisoes):1)*100);
				data.somaprodconversaoPacotesBasicoDistribuidor= (((data.somaPacotesBasico / data.somarevisoes)<1?(data.somaPacotesBasico / data.somarevisoes):1)*100);
				data.somaprodconversaoPacotesIntermediarioDistribuidor= (((data.somaPacotesIntermediario / data.somarevisoes)<1?(data.somaPacotesIntermediario / data.somarevisoes):1)*100);
				data.somaprodconversaoPacotesCompletoDistribuidor= (((data.somaPacotesCompleto / data.somarevisoes)<1?(data.somaPacotesCompleto / data.somarevisoes):1)*100);
				data.somaticketMedioDistribuidor =   data.somaProdServicosDistribuidor / data.somarevisoes;
				data.somafaturamentoTicketMedioDistribuidor = data.somafaturamentoDistribuidor/data.somarevisoes;


				consultores.value.forEach((consultor) => {

					animate.value = false;
					const arrayNomes = consultor.nomeConsultor.split(" ");
					consultor.nomeConsultor = arrayNomes.length>1 ? `${arrayNomes[0]} ${arrayNomes[1].substring(0, 1)}`: arrayNomes;
					consultor.ticketMedioDistribuidor = (consultor.prodServicosDistribuidor / consultor.revisoes);
				

					const calculoTicketMedioPacote = consultor.pacotes / consultor.revisoes;
				
					consultor.ticketMedioPacote = isFinite(calculoTicketMedioPacote) ? calculoTicketMedioPacote : 0;
					consultor.ticketMedioPacote = consultor.ticketMedioPacote > 1 ? 1 : consultor.ticketMedioPacote;
					consultor.ticketMedioGeral = consultor.prodServicosGeral / consultor.revisoes;
					consultor.prodconversaoPacotesDistribuidor = (((consultor.pacotes / consultor.revisoes)<1?(consultor.pacotes / consultor.revisoes):1)*100);
					consultor.prodconversaoPacotesBasicoDistribuidor = (((consultor.pacotesBasico / consultor.revisoes)<1?(consultor.pacotesBasico / consultor.revisoes):1)*100);
					consultor.prodconversaoPacotesIntermediarioDistribuidor = (((consultor.pacotesIntermediario / consultor.revisoes)<1?(consultor.pacotesIntermediario / consultor.revisoes):1)*100);
					consultor.prodconversaoPacotesCompletoDistribuidor = (((consultor.pacotesCompleto / consultor.revisoes)<1?(consultor.pacotesCompleto / consultor.revisoes):1)*100);
					consultor.faturamentoTicketMedioDistribuidor= (consultor.faturamentoDistribuidor/consultor.revisoes);

					//tratando os dados que estão retornando infinito
					consultor.ticketMedioGeral = consultor.ticketMedioGeral && consultor.ticketMedioGeral != Infinity ? consultor.ticketMedioGeral : 0;
					consultor.prodconversaoPacotesDistribuidor = consultor.prodconversaoPacotesDistribuidor && consultor.prodconversaoPacotesDistribuidor != Infinity ? consultor.prodconversaoPacotesDistribuidor : 0;
					consultor.prodconversaoPacotesBasicoDistribuidor = consultor.prodconversaoPacotesBasicoDistribuidor && consultor.prodconversaoPacotesBasicoDistribuidor != Infinity ? consultor.prodconversaoPacotesBasicoDistribuidor : 0;
					consultor.prodconversaoPacotesIntermediarioDistribuidor = consultor.prodconversaoPacotesIntermediarioDistribuidor && consultor.prodconversaoPacotesIntermediarioDistribuidor != Infinity ? consultor.prodconversaoPacotesIntermediarioDistribuidor : 0;
					consultor.prodconversaoPacotesCompletoDistribuidor = consultor.prodconversaoPacotesCompletoDistribuidor && consultor.prodconversaoPacotesCompletoDistribuidor != Infinity ? consultor.prodconversaoPacotesCompletoDistribuidor : 0;
					consultor.ticketMedioDistribuidor = consultor.ticketMedioDistribuidor && consultor.ticketMedioDistribuidor != Infinity ? consultor.ticketMedioDistribuidor : 0;
					consultor.faturamentoTicketMedioDistribuidor = consultor.faturamentoTicketMedioDistribuidor && consultor.faturamentoTicketMedioDistribuidor != Infinity ? consultor.faturamentoTicketMedioDistribuidor : 0;
					data.somaprodconversaoPacotesDistribuidor = data.somaprodconversaoPacotesDistribuidor && data.somaprodconversaoPacotesDistribuidor != Infinity ? data.somaprodconversaoPacotesDistribuidor : 0;
					data.somaprodconversaoPacotesBasicoDistribuidor = data.somaprodconversaoPacotesBasicoDistribuidor && data.somaprodconversaoPacotesBasicoDistribuidor != Infinity ? data.somaprodconversaoPacotesBasicoDistribuidor : 0;
					data.somaprodconversaoPacotesIntermediarioDistribuidor = data.somaprodconversaoPacotesIntermediarioDistribuidor && data.somaprodconversaoPacotesIntermediarioDistribuidor != Infinity ? data.somaprodconversaoPacotesIntermediarioDistribuidor : 0;
					data.somaprodconversaoPacotesCompletoDistribuidor = data.somaprodconversaoPacotesCompletoDistribuidor && data.somaprodconversaoPacotesCompletoDistribuidor != Infinity ? data.somaprodconversaoPacotesCompletoDistribuidor : 0;
					data.somaticketMedioGeral = data.somaticketMedioGeral && data.somaticketMedioGeral != Infinity ? data.somaticketMedioGeral : 0;
				});



				const arrayConsultores = consultores.value.map(consultor => consultor.nomeConsultorTratado);
				const arrayTicketMedio = consultores.value.map(consultor => consultor.ticketMedioDistribuidor.toFixed(2));
				const arrayPercentualPacotes = consultores.value.map(consultor => {
					const percentual = (consultor.ticketMedioPacote * 100).toFixed(2);
					return percentual;
				});

				const arrayNumbers = arrayTicketMedio.map(Number); //transforma o array de String em array de Number
				const maiorValorDoArrayTicketMedio = Math.max(...arrayNumbers); //Pega o maior valor dentro desse array de ticket medio.

				var max = 10;
				if(metas.metaticketmedio){
					max = maiorValorDoArrayTicketMedio >  metas.metaticketmedio ? Math.round(maiorValorDoArrayTicketMedio) + 1 : Math.round(metas.metaticketmedio) + 1; //verifica qual é o maior, a meta ou o maior valor do array, arredonda e soma 1.
				}

				options = geraOptions(arrayConsultores, max);
				series = geraSeries(arrayTicketMedio);


				options2 = geraOptions2(arrayConsultores);
				series2 = geraSeries2(arrayPercentualPacotes);

				animate.value = await false;

					if(chartInstance!=null && chartInstance2!=null){
						chartInstance.updateOptions(options);
						chartInstance.updateSeries(series);
						chartInstance2.updateOptions(options2);
						chartInstance2.updateSeries(series2);
					}

			}
				return {consultores, ...toRefs(data), datePicker,
				bloqueiaGrafico,metaConversaoWarning, metaTMWarning,
				listaConsultores, ptBr,animate,step, exportarXLSX, exportPDF, distribuidor, loadingExport,  filtradados, title, activeName};
	}
})

