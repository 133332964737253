

import { defineComponent, reactive, onMounted, toRefs, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getIndicadorVendaMensal} from "@/services/OnePageReportService";
import moment from "moment";
import { ContentLoader } from 'vue-content-loader';


export default defineComponent({
    name: "indicadores-vendas-card",
    components: {
        ContentLoader
    },
    props: {
        metas: Object,
        loading: Boolean,
        dataFaturamentoTotal: Array,
        widgetClasses: String,
        widgetColor: String,
        strokeColor: String,
        cardClasses: String,
    },

    setup(props)  {
        let animate = ref(true);
        const labelColor = getCSSVariableValue("cor_layout_2");
        const borderColor = getCSSVariableValue("cor_layout_2");
        const color = getCSSVariableValue("cor_layout_2");
        const strokeColor = ref('#0bb783');
      
        const data = reactive({
          Indicadores: {
            valortotalProdutosGeral: 0,
            valorRevisoes: 0,
            valorticketMedioGeral: 0,
            valorTicketMedioDistribuidor: 0,
            somaProdutosDistribuidor: 0,
          },
        });
        
        
        let somaTicketMedioGeralFormat = ref("");
        let somaTicketMedioDistribuidorformat = ref("");
        let somaValorTotalNaoRealizadoFormat =  ref("");
        let somaValorDistribuidorFormat =  ref("");
        let somaPercentualConversaoPacotesNaoRealizadoFormat = ref("");
        let somaPercentualConversaoPacotesFormat = ref("");
        let somaValorTotalFormat = ref("");
        let somaPercentualFaturamentoDistribuidorFormat = ref("");
        let somaPercentualFaturamentoDistribuidor = ref(0);
        let somaRevisoes = ref(0);
        let somaTicketMedioDistribuidor = ref(0);
        let somaTicketMedioGeral = ref(0);
        let somaProdutosDistribuidor = ref(0);
        let somaProdutosGeral = ref(0);
        let somaValorDistribuidor = ref(0);
        let somaValorTotal = ref(0);
        let somaValorTotalNaoRealizado = ref(0);
        let chart = ref({});
        let series = ref({});

        function defineDataDuplicado(object,tipo,indexFinal){

            if(tipo == 'sum'){
                if(object.data[indexFinal].mes == '12'){
                    object.data[indexFinal].mes = '01';
                    object.data[indexFinal].ano = String(parseInt(object.data[indexFinal].ano)+1);
                }else{
                    object.data[indexFinal].mes = String(parseInt(object.data[indexFinal].mes)+1);
                    if(parseInt(object.data[indexFinal].mes)<10 ){
                        object.data[indexFinal].mes = '0'+object.data[indexFinal].mes ;
                    }
                }
            }else if(tipo == 'sub'){
                if(object.data[0].mes == '01'){
                    object.data[0].mes = '12';
                    object.data[0].ano = String(parseInt(object.data[0].ano)-1);
                }else{
                    object.data[0].mes = String(parseInt(object.data[0].mes)-1);
                    if(parseInt(object.data[0].mes)<10 ){
                        object.data[0].mes = '0'+object.data[0].mes ;
                    }
                }
            }
        }

        function setPadding(qtdMeses){
            let tam = -15;
            switch (qtdMeses) {
                case 2:
                    tam = -240;
                    break;
                case 3:
                    tam = -120;
                    break;
                case 4:
                    tam = -80;
                    break;
                case 5:
                    tam = -60;
                    break;
                case 6:
                    tam = -40;
                    break;
                case 7:
                case 8:
                case 9:
                    tam = -30;
                    break;
                case 10:
                case 11:
                    tam = -20;
                    break;
                case 12:
                    tam = -18;
                    break;
                case 13:
                    tam = -15;
                    break;
                case 14:
                    tam = -14;
                    break;
                case 15:
                    tam = -13;
                    break;
                case 16:
                    tam = -12.5;
                    break;
                case 17:
                    tam = -11.5;
                    break;
                case 18:
                    tam = -10.5;
                    break;
                case 19:
                    tam = -10.5;
                    break;
                case 20:
                    tam = -9.5;
                    break;
                case 21:
                    tam = -9.5;
                    break;
                case 22:
                    tam = -9.5;
                    break;
                case 23:
                    tam = -9.5;
                    break;
                case 24:
                    tam = -9.5;
                    break;
            }

            chartIndicadores.value.grid.padding.left = tam;
            chartIndicadores.value.grid.padding.right = tam;
        }

        watch(() => props.dataFaturamentoTotal, () => {
            const data:any = props.dataFaturamentoTotal;
            const dataOriginalArray:any = Array.from(data.data);
            const dataCopia:any = Object.assign({}, props.dataFaturamentoTotal);

            var indexFinal = dataOriginalArray.length;
            const posicaoInicial = Object.assign({}, dataOriginalArray[0]);
            const posicaoFinal = Object.assign({}, dataOriginalArray[indexFinal-1]);
            dataOriginalArray.unshift(posicaoInicial);
            dataOriginalArray.push(posicaoFinal);
            setPadding(indexFinal);
            indexFinal++;
            dataCopia.data = dataOriginalArray;
            defineDataDuplicado(dataCopia,'sum',indexFinal);
            defineDataDuplicado(dataCopia,'sub',indexFinal);
            listaIndicadorVendaMensal(dataCopia.data, data.data, data.oneMounth);
        });

        async function listaIndicadorVendaMensal(responseGrafico, responseOriginal, oneMounth){   
            animate.value = true; 
            seriesIndicadores.value[0].data = responseGrafico.map((ticketMedioGeral, i) =>  {
                return [moment(ticketMedioGeral.ano+'-'+ticketMedioGeral.mes+'-'+"01").unix(), ticketMedioGeral.ticketMedioGeral]
            });
            
            if(seriesIndicadores.value[0].data.length === 1){
                const refSerieIndicadorUnix = seriesIndicadores.value[0].data[0][0] + 10;
                const refSerieIndicadorData = seriesIndicadores.value[0].data[0][1];
        
                seriesIndicadores.value[0].data.push([refSerieIndicadorUnix, refSerieIndicadorData])
            }

            somaRevisoes.value = 0;
            somaProdutosDistribuidor.value = 0;
            somaProdutosGeral.value = 0;
            somaValorDistribuidor.value = 0;
            somaValorTotal.value = 0;
            somaTicketMedioGeral.value = 0;
            somaTicketMedioDistribuidor.value = 0;
            somaValorTotalNaoRealizado.value = 0;
            somaTicketMedioGeralFormat.value = "0,00";
            somaTicketMedioDistribuidorformat.value = "0,00";

            if(!responseOriginal[0] && !responseOriginal[1]){
                return;
            };

            if(oneMounth){
                const dataLastMounth = responseOriginal[responseOriginal.length - 1];
                if(!dataLastMounth)
                    return;
                somaRevisoes.value += dataLastMounth.revisoes;
                somaProdutosDistribuidor.value += dataLastMounth.totalProdutosDistribuidor;
                somaProdutosGeral.value += dataLastMounth.totalProdutosGeral;
                somaValorDistribuidor.value += dataLastMounth.valorDistribuidor;
                somaValorTotal.value += dataLastMounth.valorTotal;
                somaValorTotalNaoRealizado.value += dataLastMounth.valorTotalNaoRealizado;
            } else {
                responseOriginal.forEach((item) => {
                    if(!item)
                        return;
                    somaRevisoes.value += item.revisoes;
                    somaProdutosDistribuidor.value += item.totalProdutosDistribuidor;
                    somaProdutosGeral.value += item.totalProdutosGeral;
                    somaValorDistribuidor.value += item.valorDistribuidor;
                    somaValorTotal.value += item.valorTotal;
                    somaValorTotalNaoRealizado.value += item.valorTotalNaoRealizado;
                });
            }
            const temRevisao = somaRevisoes.value && somaRevisoes.value > 0 ? somaRevisoes.value : 0;
            somaTicketMedioDistribuidor.value = temRevisao ? somaProdutosDistribuidor.value / temRevisao : 0;
            somaTicketMedioDistribuidor.value = isNaN(somaTicketMedioDistribuidor.value) || !somaTicketMedioDistribuidor.value ? 0 : somaTicketMedioDistribuidor.value;
            somaTicketMedioGeral.value = temRevisao ? somaProdutosGeral.value / temRevisao : 0;
            somaTicketMedioGeral.value = isNaN(somaTicketMedioGeral.value) || !somaTicketMedioGeral.value ? 0 : somaTicketMedioGeral.value;

            somaTicketMedioGeralFormat.value = somaTicketMedioGeral.value.toFixed(2);
            somaTicketMedioGeralFormat.value  = somaTicketMedioGeralFormat.value ? somaTicketMedioGeralFormat.value : "0,00";
            somaTicketMedioDistribuidorformat.value = somaTicketMedioDistribuidor.value.toFixed(2);
            somaTicketMedioDistribuidorformat.value  = somaTicketMedioDistribuidorformat.value ? somaTicketMedioDistribuidorformat.value  : "0,00";
            animate.value = false;
        };

        onMounted(async () => {});

        const chartIndicadores =  ref({
            chart: {
                offsetY: -25,
                fontFamily: "inherit",
                type: "area",
                toolbar: {
                    show: false,
                },
                
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: false,
                },
            },
            grid: {
		    	show: false,
                clipMarkers: true,
                padding: {
                	left: -18,
                    right: -18,
                    top: 20,
                    bottom: 10
                }
            },
            plotOptions: {},
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: true, 
                dropShadow:false,
                borderRadius:false,
                offsetY: -5,
                style: {
                    fontSize: '10px',
                    colors: ['var(--cor_indicadores_1)'],
               },
                distributed: true,
                background : false,
                    
            },
            
            
            fill: {
                type: "solid",
                opacity: 0,
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: [strokeColor.value],
            },
            xaxis: {
                categories: [1, 2, 3, 4, 5, 6,7,8],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    style: {
                        colors: labelColor,
                        fontSize: "12px",
                    },
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        color: borderColor,
                        width: 1,
                        dashArray: 3,
                    },
                },
            },
            yaxis: {            
                labels: {
                    show: false,
                    style: {
                        colors: labelColor,
                        fontSize: "12px",
                    },
                },
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                },
				x: {
					formatter: function (val) {
						//return val;
                        moment.locale('pt-br'); 
						return moment.unix(val).format("MMMM YYYY");
					},
				},
				
				y: {
					formatter: function (val) {
						return  + val;
					},
				},
                marker: {
                    show: false,
                },
            },
            markers: {
                colors: [color],
                strokeColor: [strokeColor.value],
                strokeWidth: 5,
            },
        });

        const categoriesIndicadores = ref([{
			type: 'string',
			categories: ['', '', '', '', '', '']
		}]);


        const seriesIndicadores = ref([{
            name: 'Ticket Médio',
            data: [[0, 0], [0, 0], [0,0], [0,0], [0,0], [0,0]],
        }]);

        const totalRevisoes = ref(0);
        const nomesMeses = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
               
        return {
            chartIndicadores,
            seriesIndicadores,
            chart,
            series,
            somaPercentualConversaoPacotesFormat,
            somaPercentualFaturamentoDistribuidorFormat,
            somaPercentualFaturamentoDistribuidor,
            somaPercentualConversaoPacotesNaoRealizadoFormat,
            somaValorTotalNaoRealizadoFormat,
            somaValorDistribuidorFormat,
            somaValorTotalFormat,
            somaProdutosGeral,
            somaProdutosDistribuidor,
            somaRevisoes,
            somaTicketMedioGeral,
            somaTicketMedioGeralFormat,
            somaTicketMedioDistribuidor,
            somaTicketMedioDistribuidorformat,
            nomesMeses,
            totalRevisoes,
            categoriesIndicadores,
            ...toRefs(data),
            animate
        }
   },
});
